<div class="popup-model-header">
  <h1 mat-dialog-title>Adjuntar archivo</h1>
</div>

<div class="popup-model-content" >
  <!-- <mat-select [(ngModel)]="choosenEmoji">
          <mat-option *ngFor="let emoji of emojis" [value]="emoji">
            {{ emoji }}
          </mat-option>
        </mat-select> -->
        <app-file-upload afsCollection="evidencias" [afsDocPath]="'ordenesServicio/'+ id" [fileCategory]="category" (uploadCompleteEvent)="uploadComplete($event)"></app-file-upload>

</div>

<div class="popup-model-footer" align="end">
  <button mat-raised-button (click)="dialogRef.close()" color="accent">
    Cancelar
  </button>
</div>
