<div class="wrap">
  <div *ngIf="error" class="alert alert-warning" role="alert">
    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
    <span class="sr-only">Error:</span> {{error}}
  </div>
  <div class="login-header">
    <h2>LEMOTEK
      <br /> "SmartLink"
      <br /> Quality Control Software
    </h2>
  </div>

  <div class="loginmodal-container">
    <h3>Cambia tu contraseña</h3>
    <!-- <br> -->
    <form [formGroup]="userForm" (ngSubmit)="changePassword($event, password.value)" class="form-dimensions">

      <mat-form-field class="full-width">
        <input #password type="password" id="password" matInput placeholder="Nueva contraseña" formControlName="password" required>
        <!-- class="form-control" -->
      </mat-form-field>
      <div *ngIf="formErrors.password" class="alert-message">
        {{ formErrors.password }}
      </div>

      <mat-form-field class="full-width">
        <input #password type="password" id="confirmPassword" matInput class="form-control" placeholder="Confirmar tu nueva contraseña"
          formControlName="confirmPassword" required>
      </mat-form-field>

      <div *ngIf="userForm.controls.confirmPassword.errors?.MatchPassword" class="alert-message">
        Las contraseñas no coinciden.
      </div>

      <br>
      <button class="btn btn-md btn-primary btn-block" mat-raised-button color="primary" type="submit" [disabled]="!userForm.valid">Cambiar contraseña</button>

    </form>
  </div>
</div>
