import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  // MatProgressBarModule,
  MatCheckboxModule
} from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from "@angular/material/expansion";
// import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {MatBadgeModule} from '@angular/material/badge';

import { TableModule } from 'primeng/table';
import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';
import { FileUploadModule } from 'primeng/fileupload';
import { ChipsModule } from 'primeng/chips';
import { ProgressBarModule } from 'primeng/progressbar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MessagesModule } from 'primeng/messages';
import { EditorModule } from 'primeng/editor';
import { DropdownModule } from 'primeng/dropdown';
import { PickListModule } from 'primeng/picklist';
import { SidebarModule } from 'primeng/sidebar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ListboxModule } from 'primeng/listbox';
import { RippleModule } from 'primeng/ripple';
import { AuthorizationDirective } from 'app/directives/authorization.directive';

// import {
//   CovalentNotificationsModule
// } from '@covalent/core';

const MATERIAL_MODULES: any[] = [
  MatToolbarModule,
  MatListModule,
  MatIconModule,
  MatInputModule,
  MatCardModule,
  MatButtonModule,
  MatGridListModule,
  MatMenuModule,
  MatTabsModule,
  MatSidenavModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatSelectModule,
  MatSnackBarModule,
  // MatProgressBarModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTableModule,
  MatTooltipModule,
  MatChipsModule,
  MatSlideToggleModule,
  MatExpansionModule,
  MatBadgeModule
];
const PRIMENG_MODULES: any[] = [
  FileUploadModule,
  ContextMenuModule,
  ConfirmDialogModule,
  SidebarModule,
  PickListModule,
  DropdownModule,
  EditorModule,
  SharedModule,
  MessagesModule,
  OverlayPanelModule,
  TableModule,
  ProgressBarModule,
  ChipsModule,
  TooltipModule,
  ListboxModule,
  RippleModule
];
// const COVALENT_MODULES: any[] = [
//   CovalentNotificationsModule
// ];
@NgModule({
  imports: [
    CommonModule,
    ...MATERIAL_MODULES,
    ...PRIMENG_MODULES,
    // ...COVALENT_MODULES
  ],
  exports: [
    ...MATERIAL_MODULES,
    ...PRIMENG_MODULES,
    // ...COVALENT_MODULES,
    AuthorizationDirective
  ],
  declarations: [AuthorizationDirective],
  providers: [
    ConfirmationService
  ]
})
export class NgSharedModule { }
