import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { MomentModule } from 'ngx-moment';
import { AppRoutingModule } from './app-routing.module';
import { NgSharedModule } from './@shared/shared.module';
import { CoreModule } from './@core/core.module';
import { DropZoneDirective } from './directives/drop-zone.directive';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { FrontPageComponent } from './pages/front-page/front-page.component';
import { InstrumentosModalComponent } from './pages/instrumentos-modal/instrumentos-modal.component';
import { InstrumentosSearchComponent } from './pages/instrumentos-search/instrumentos-search.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { ModelSearchComponent } from './pages/model-search/model-search.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ProductosModalComponent } from './pages/productos-modal/productos-modal.component';
import { PropiedadAddEditComponent } from './pages/propiedad-add-edit/propiedad-add-edit.component';
import { UnidadesMedicionAddEditComponent } from './pages/unidades-medicion-add-edit/unidades-medicion-add-edit.component';
import { TestsAddEditComponent } from './pages/tests-add-edit/tests-add-edit.component';
import { AttachmentsModalComponent } from './components/attachments-modal/attachments-modal.component';
import { UserButtonDialogComponent } from './components/user-button-dialog/user-button-dialog.component';
import { MenuListItemComponent } from './components/menu-list-item/menu-list-item.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { environment } from '../environments/environment';
import { IonicModule } from '@ionic/angular';
import { AppConfigModule } from './app-config.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { FileSizeModule } from './pipes/file-size/file-size.module';
import { Serial } from '@ionic-native/serial/ngx';

@NgModule({
    declarations: [
        DropZoneDirective,
        AppComponent,
        AttachmentsModalComponent,
        ChangePasswordComponent,
        FileUploadComponent,
        FrontPageComponent,
        HomeComponent,
        InstrumentosModalComponent,
        InstrumentosSearchComponent,
        LoginPageComponent,
        MenuListItemComponent,
        ModelSearchComponent,
        NavbarComponent,
        PageNotFoundComponent,
        ProductosModalComponent,
        PropiedadAddEditComponent,
        TestsAddEditComponent,
        UnidadesMedicionAddEditComponent,
        UserButtonDialogComponent
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    NgSharedModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    FormsModule,
    ReactiveFormsModule,
    AppConfigModule,
    MomentModule,
    environment.production
      ? ServiceWorkerModule.register('/ngsw-worker.js')
      : [],
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    ToastModule,
    FileSizeModule
  ],
    providers: [
      DatePipe,
      MessageService,
      Serial
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
