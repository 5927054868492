<div  class="container mx-auto">
  <form #parametrosMed="ngForm" (ngSubmit)="submit()">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Propiedades</mat-card-title>
        <mat-card-subtitle>Agregar nueva propiedad</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>


        <!-- <mat-form-field>
          <input matInput name="nombre" [(ngModel)]="nombre" type="text" placeholder="Nombre" required>
        </mat-form-field> -->
        <div class="grid-container">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Propiedad Medible</mat-label>
            <mat-select name="propiedad" [(ngModel)]="propiedadMedible" placeholder="Propiedad Medible" required (selectionChange)="onSelectPropiedad()"
              [compareWith]="compareProp">
              <mat-option *ngFor="let prop of propsMedibles | async" [value]="prop">
                {{prop.nombre}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="field" appearance="outline">
            <mat-label>Magnitud</mat-label>
            <mat-select name="magnitud" [(ngModel)]="magnitud" placeholder="Magnitud" required [compareWith]="compareProp" disabled>
              <mat-option *ngFor="let unidad of unidades" [value]="unidad.magnitud">
                {{unidad.magnitud.nombre}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="field" appearance="outline">
            <mat-label>Unidades</mat-label>
            <mat-select name="unidadMed" [(ngModel)]="unidadMed" placeholder="Unidades" [compareWith]="compareProp" required>
              <mat-option *ngFor="let unidad of unidades" [value]="unidad">
                {{unidad.unidad}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div>
          </div>

          <mat-form-field class="field" appearance="outline">
            <mat-label>Alcance Inferior</mat-label>
            <input matInput name="alcanceInf" [(ngModel)]="alcanceInf" type="number" placeholder="Alcance Inferior" required>
            <span matSuffix>{{unidadMed?.simbolo}}</span>
          </mat-form-field>
          <mat-form-field class="field" appearance="outline">
            <mat-label>Alcance Superior</mat-label>
            <input matInput name="alcanceSup" [(ngModel)]="alcanceSup" type="number" placeholder="Alcance Superior" required>
            <span matSuffix>{{unidadMed?.simbolo}}</span>
          </mat-form-field>

          <div>
          </div>
          <div>
          </div>

          <mat-form-field class="field" appearance="outline">
            <mat-label>Columnas por línea</mat-label>
            <input matInput name="arrayLength" [(ngModel)]="arrayLength" type="number" placeholder="Columnas por línea">
          </mat-form-field>

          <mat-form-field class="field" appearance="outline">
            <mat-label>Index del valor</mat-label>
            <input matInput name="indexValor" [(ngModel)]="indexValor" type="number" placeholder="Index del valor" required>
          </mat-form-field>
          <mat-form-field class="field" appearance="outline">
            <mat-label>Index de la unidad</mat-label>
            <input matInput name="indexUnidad" [(ngModel)]="indexUnidad" type="number" placeholder="Index de la unidad" [disabled]="arrayLength < 2">
          </mat-form-field>

          <mat-form-field class="field" appearance="outline">
            <mat-label>Index del tipo</mat-label>
            <input matInput name="indexTipo" [(ngModel)]="indexTipo" type="number" placeholder="Index del tipo" [disabled]="arrayLength < 2">
          </mat-form-field>

          <mat-form-field class="field stringTipo" appearance="outline" *ngIf="indexTipo != undefined || indexTipo != null">
            <mat-label>String del tipo</mat-label>
            <input matInput name="stringTipo" [(ngModel)]="stringTipo" type="text" placeholder="String del tipo" [disabled]="arrayLength < 2">
          </mat-form-field>


        </div>
      </mat-card-content>
      <mat-card-actions align="start">
        <button mat-raised-button type="submit" color="accent" [disabled]="!parametrosMed.valid">Aceptar</button>
        <button mat-raised-button type="button" (click)="closeDialog()">Cancelar</button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
