import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from '@labqc-services/data.service';

@Component({
  selector: 'app-productos-modal',
  templateUrl: './productos-modal.component.html',
  styleUrls: ['./productos-modal.component.scss']
})
export class ProductosModalComponent implements OnInit {
  product: {
    productName: string;
    code: string;
    description: string;
    productType: { nombre: string; id: string };
    active: boolean;
  };
  selectedProductType: { nombre: string; id: string };

  updateAction: boolean;

  id: any;
  productName = '';
  code = '';
  description = '';
  productType: any;
  active: boolean;

  productTypeList: Promise<any>;

  // checks = [
  //   { nombre: 'weight', checked: false, disabled: false },
  //   { nombre: 'thickness', checked: false, disabled: false },
  //   { nombre: 'hardness', checked: false, disabled: false },
  //   { nombre: 'torque', checked: false, disabled: false }
  // ];
  message: string;

  constructor(
    private dialogRef: MatDialogRef<ProductosModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: DataService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    if (this.data) {
      this.fillUpdateForm();
    }
    this.productTypeList = this.dataService.getAll('productTypes');
  }

  // valueChange(index, event$) {
  //   this.checks[index].checked = event$.checked;
  // }

  fillUpdateForm() {
    console.log(this.data);
    this.updateAction = true;
    this.id = this.data.id;
    this.productName = this.data.productName;
    this.code = this.data.code;
    this.description = this.data.description;
    this.productType = this.data.productType;
    this.active = this.data.active;
    // if (this.data.attributes) {
    //   this.data.attributes.forEach(attribute => {
    //     const index = this.checks.findIndex(
    //       check => check.nombre === attribute.nombre
    //     );
    //     if (index > -1) {
    //       // Si ya hay un trabajo para el parámetro marca el checkbox y lo deshabilita
    //       if (attribute.checked === true) {
    //         this.checks[index].checked = true;
    //         this.checks[index].disabled = true;
    //       }
    //     }
    //   });
    // }
  }

  submit() {
    this.product = {
      productName: this.productName,
      code: this.code,
      description: this.description,
      productType: this.productType,
      active: this.active
      // attributes: this.checks
    };
    if (!this.updateAction) {
      this.dataService
        .create(this.product, 'productos')
        .then(res => {
          this.message = 'Producto Guardado';
          console.log(res);
          this.dialogRef.close(res);
        });
    } else {
      this.actualizaOrden();
    }
  }

  async actualizaOrden() {
    if (this.updateAction) {
      this.message = 'Actualizando documento...';
      this.snackBar.open(this.message);
      await this.dataService.update(this.id, this.product, 'productos');
      this.message = 'Documento Actualizado!!!';
      this.openSnackBar();
      this.dialogRef.close();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openSnackBar() {
    this.snackBar.open(this.message, null, {
      duration: 1500
    });
  }

  selectedProduct($event) {
    console.log($event);
    // const selectedType = this.productTypeList.find(
    //   magnitud => magnitud.id === $event.value
    // );
    // console.log(this.selectedProductType);
    // this.selectedProductType = selectedType;
  }

  compareProp(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }
}
