import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-attachments-modal',
  templateUrl: './attachments-modal.component.html',
  styleUrls: ['./attachments-modal.component.scss']
})
export class AttachmentsModalComponent implements OnInit {
  id: string;
  category: string;
  constructor(
    public dialogRef: MatDialogRef<AttachmentsModalComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any,

  ) { }

  ngOnInit() {
    this.id = this.data.id;
    this.category = this.data.category;
    console.log(this.data);
  }

  uploadComplete($event) {
    this.dialogRef.close();
    console.log($event);
  }

}
