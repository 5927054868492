<mat-toolbar color="primary">
  <button mat-icon-button id="menu" (click)="navService.openNav()" class="inline-block sm:!hidden">
    <mat-icon>menu</mat-icon>
  </button>

  <img id="logo" src="assets/img/logo_datel.gif" height="64px" class="hidden" />

  <div class="hidden sm:inline" *ngIf="(authService.user$ | async) as authState">
    <button mat-button [routerLink]="['/home']" routerLinkActive="active">
      <mat-icon class="svg-icon" svgIcon="home"></mat-icon>Inicio
    </button>

    <button mat-button [routerLink]="['./test-selection']" routerLinkActive="active" id="run-test-menu-button" authorization disabled>
      <mat-icon class="svg-icon" svgIcon="run-test"></mat-icon>Correr Prueba
    </button>

    <button mat-button [routerLink]="['./tests']" routerLinkActive="active" id="reports-menu-button" authorization disabled>
      <mat-icon class="svg-icon" svgIcon="reports"></mat-icon>Reportes
    </button>

    <button mat-button [matMenuTriggerFor]="config" routerLinkActive="active" id="config-menu-button" [disabled]="!(authService.canViewConfigMenu())">
      <mat-icon class="svg-icon" svgIcon="catalogs"></mat-icon>Catálogos
    </button> <!-- [disabled]="!(authService.canViewConfigMenu())" -->
    <mat-menu #config="matMenu" [overlapTrigger]="false" >
      <button mat-menu-item [routerLink]="['./tests-catalog']" routerLinkActive="active" >Métodos</button>
      <button mat-menu-item [routerLink]="['./products']" routerLinkActive="active" id="config-products-menu-button" (click)="closeMyMenu()">Productos</button>
      <button mat-menu-item [routerLink]="['./product-types']" routerLinkActive="active" id="config-product-types-menu-button" >Tipos de producto</button>
      <button mat-menu-item [routerLink]="['./instrumentos']" routerLinkActive="active" id="config-instruments-menu-button" >Instrumentos</button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="seguridad" routerLinkActive="active" id="security-menu-button" [disabled]="!(authService.canViewSecurityMenu())">
      <mat-icon class="svg-icon" svgIcon="security"></mat-icon>Seguridad
    </button> <!--[disabled]="!(authService.canViewSecurityMenu())"-->
    <mat-menu #seguridad="matMenu" [overlapTrigger]="false">
      <button mat-menu-item [routerLink]="['./usuarios']" routerLinkActive="active" id="menu-users-button" >Usuarios</button>
      <button mat-menu-item [routerLink]="['./preferences']" routerLinkActive="active" id="menu-security-button" disabled>Ajustes de seguridad</button>
    </mat-menu>

    <button mat-button *ngIf="(authService.canViewAdminMenu())" [matMenuTriggerFor]="admon" routerLinkActive="active"  id="admin" [disabled]="!(authService.canViewAdminMenu())">
      <mat-icon class="svg-icon" svgIcon="settings"></mat-icon>Ajustes
    </button> <!--[disabled]="!(authService.canViewAdminMenu(user))"-->
    <mat-menu #admon="matMenu" [overlapTrigger]="false">
      <button mat-menu-item [routerLink]="['./equipos']" routerLinkActive="active" id="admin-equipment" >Tipo de Instrumento</button>
      <button mat-menu-item [routerLink]="['./props-medibles']" routerLinkActive="active" id="admin-measurable-properties" >Prop. Medibles</button>
      <button mat-menu-item [routerLink]="['./unidades-medida']" routerLinkActive="active" id="admin-units" >Unidades</button>
      <button mat-menu-item [routerLink]="['./magnitudes']" routerLinkActive="active" id="admin-unit-groups" >Grupos de unidades</button>
      <button mat-menu-item [routerLink]="['./capture-sample-from-serial']" routerLinkActive="active" id="menu-capture-button" >Capturar desde serial</button>
      <button mat-menu-item [routerLink]="['./permissions']" routerLinkActive="active" id="menu-permissions-button" >Permisos</button>
      <button mat-menu-item [routerLink]="['./preferences']" routerLinkActive="active" id="menu-permissions-button" >Preferencias</button>
    </mat-menu>

  </div>

  <span class="spacer"></span>
  <div class="hidden sm:inline" *ngIf="(authService.user$  | async) as user">
    <div class="logged-in-as">Iniciaste sesión como:</div>
    <div class="name">{{user?.nombre}}</div>
  </div>

  <button mat-button *ngIf="!(authService.user$ | async) && router.url !== '/login'" (click)=login()>Entrar</button>
  <!-- <button mat-button *ngIf="(afAuth.authState | async)" (click)=fireLogout()>Cerrar Sesión</button> -->
  <div class="topDivUserPhoto" (click)="userMenu()" mat-button *ngIf="(authService.user$  | async)">
    <img class="profileIcon" src="assets/img/user-thumbnail.png" />
  </div>

</mat-toolbar>
