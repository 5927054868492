import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '@labqc-pages/home/home.component';
import { MarcaDetailComponent } from '@labqc-pages/equipos/marcas/marca-detail/marca-detail.component';
import { LoginPageComponent } from '@labqc-pages/login-page/login-page.component';
import { PageNotFoundComponent } from '@labqc-pages/page-not-found/page-not-found.component';
import { ChangePasswordComponent } from '@labqc-pages/change-password/change-password.component';
import { FrontPageComponent } from '@labqc-pages/front-page/front-page.component';
import { IsOperatorGuard } from '@labqc-core/is-operator.guard';
import { IsAdminGuard } from '@labqc-core/is-admin.guard';

const appRoutes: Routes = [
  { path: '', component: FrontPageComponent },
  { path: 'home', component: HomeComponent},
  { path: 'test-selection', loadChildren: () => import('./pages/test-selection/test-selection.module').then(m => m.TestSelectionModule) },
   /* , canActivate: [IsOperatorGuard] */
  { path: 'run-test', loadChildren: () => import('./pages/run-test/run-test.module').then(m => m.RunTestModule) },
  { path: 'login', component: LoginPageComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'usuarios', loadChildren: () => import('./pages/usuarios/usuarios.module').then(m => m.UsuariosModule) },
  { path: 'marcaDetail/:id', component: MarcaDetailComponent },
  { path: 'preferences', loadChildren: () => import('./pages/preferences/preferences.module').then(m => m.PreferencesModule)},
  { path: 'instrumentos', loadChildren: () => import('./pages/instrumentos/instrumentos.module').then(m => m.InstrumentosModule) },
  { path: 'equipos', loadChildren: () => import('./pages/equipos/equipos.module').then(m => m.EquiposModule),
  canActivate: [IsAdminGuard]},
  { path: 'product-types', loadChildren: () => import('./pages/product-type/product-type.module').then(m => m.ProductTypeModule)},
  { path: 'products', loadChildren: () => import('./pages/productos/productos.module').then(m => m.ProductosModule)},
  { path: 'tests-catalog', loadChildren: () => import('./pages/tests-catalog/tests-catalog.module').then(m => m.TestsCatalogModule) },
  { path: 'tests', loadChildren: () => import('./pages/tests/tests.module').then(m => m.TestsModule)},
  { path: 'test-detail', loadChildren: () => import('./pages/test-detail/test-detail.module').then(m => m.TestDetailModule) },
  { path: 'props-medibles', loadChildren: () => import('./pages/props-medibles/props-medibles.module').then(m => m.PropsMediblesModule)},
  { path: 'magnitudes', loadChildren: () => import('./pages/magnitudes/magnitudes.module').then(m => m.MagnitudesModule)},
  { path: 'unidades-medida', loadChildren: () => import('./pages/unidades-medicion/unidades-medicion.module').then(m => m.UnidadesMedicionModule) },
  { path: 'capture-sample-from-serial', loadChildren: () => import('./pages/capture-sample-from-serial/capture-sample-from-serial.module').then(m => m.CaptureSampleFromSerialModule) },
  { path: 'permissions', loadChildren: () => import('./pages/permissions/permissions.module').then(m => m.PermissionsModule)},
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
