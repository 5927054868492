<form>
<p-table #tt [value]="instrumentos" [columns]="cols" dataKey="id" [loading]="loading" [rowHover]="true" [rows]="50" [paginator]="true"
  selectionMode="single" [(selection)]="selectedRow" [style]="{'font-size': '.9rem'}" [resizableColumns]="true" columnResizeMode="expand"
  [scrollable]="true" scrollHeight="400px" (onRowSelect)="selectInstrument()">

  <ng-template pTemplate="caption">
    <h3 class="table-header">Instrumentos</h3>
    <div style="text-align: left">
      <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
      <input type="text" pInputText class="p-inputtext-sm" size="50" placeholder="Filtro Global" (input)="tt.filterGlobal($event.target.value, 'contains')"
        style="width:auto">
      <button type="button" pButton class="p-button-outlined p-button-sm" (click)="resetFilters(tt)" label="Restablecer Filtros"></button>
      <!-- <div style="float:right">
        <button type="button" pButton class="p-button-outlined p-button-sm" icon="fa fa-file" iconPos="left" label="Exportar filtrados" (click)="tt.exportCSV()"></button>
        <button type="button" pButton class="p-button-outlined p-button-sm" icon="fa fa-file" iconPos="left" label="Exportar Selection" (click)="tt.exportCSV({selectionOnly:true})"></button>
      </div> -->
    </div>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" pResizableColumn>{{col.header}}</th>
    </tr>
  </ng-template>

    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td>{{rowData.modelo?.nombre}}</td>
        <td>{{rowData.modelo.descripcion}}</td>
        <td>{{rowData.serie}}</td>
        <td>{{rowData.codigoId}}</td>
        <td>{{rowData.ubicacion}}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length">
          No se encontraron registros
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      Instrumento seleccionado: {{selectedRow ? selectedRow.codigoId : 'ninguno'}}
    </ng-template>


</p-table>

<!-- <button mat-raised-button color="primary" type="submit" (click)="selectInstrument()">Selecionar</button> -->
</form>
