import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { PasswordValidation } from './password-validation';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  error: any;
  userForm: UntypedFormGroup;
  formErrors: { 'password': string; 'confirmPassword': string };
  validationMessages:
    {
      'password': { 'required': string; 'pattern': string; 'minlength': string; 'maxlength': string; };
      'confirmPassword': { 'required': string; 'pattern': string; 'minlength': string; 'maxlength': string; };
    };
  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm(): void {
    this.userForm = this.formBuilder.group({
      'password': ['', [
        // Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
        Validators.minLength(6),
        Validators.maxLength(25)
      ]
      ],
      'confirmPassword': ['', [
        // Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
        Validators.minLength(6),
        Validators.maxLength(25)
      ]
      ],
    }, {
        validator: PasswordValidation.MatchPassword // your validation method
      });
    this.userForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // reset validation messages
  }

  // Updates validation state on form changes.
  onValueChanged(data?: any) {
    this.formErrors = {
      'password': '',
      'confirmPassword': '',
    };

    this.validationMessages = {
      'password': {
        'required': 'Se requiere una contraseña.',
        'pattern': 'La contraseña debe incluir al menos una letra y un número.',
        'minlength': 'La contraseña debe contener al menos 6 caracteres.',
        'maxlength': 'La contraseña no puede exceder los 25 caracteres',
      },
      'confirmPassword': {
        'required': 'Se requiere una contraseña.',
        'pattern': 'La contraseña debe incluir al menos una letra y un número.',
        'minlength': 'La contraseña debe contener al menos 6 caracteres.',
        'maxlength': 'La contraseña no puede exceder los 25 caracteres',
      }

    };
    if (!this.userForm) { return; }
    const form = this.userForm;
    // tslint:disable-next-line:forin
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        // tslint:disable-next-line:forin
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  changePassword(event, password) {
    // event.preventDefault();
    // this.auth.changePassword(password)
    //   .then(() => {
    //     console.log('Password Changed');
    //     this.auth.setPasswordChangedFlag();
    //     // this.auth.changeEmailInUserProfile('artux@outlook.com');
    //     this.router.navigate(['']);
    //   })
    //   .catch((error) => {
    //   this.error = error;
    //     console.log(error);
    //   });
  }

}
