import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { DataService } from '@labqc-services/data.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-unidades-medicion-add-edit',
  templateUrl: './unidades-medicion-add-edit.component.html',
  styleUrls: ['./unidades-medicion-add-edit.component.scss']
})
export class UnidadesMedicionAddEditComponent implements OnInit, OnDestroy {
  unit: { unidad: any; simbolo: any; magnitud: any };
  message: string;
  id: any;
  updateAction: boolean;

  magnitudes: any;

  unidad: any;
  simbolo: any;
  magnitud: any;

  constructor(
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UnidadesMedicionAddEditComponent>,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getMagnitudes();
    if (this.data) {
      this.fillUpdateForm();
    }
  }

  fillUpdateForm() {
    this.updateAction = true;
    this.id = this.data.id;
    this.unidad = this.data.unidad;
    this.simbolo = this.data.simbolo;
    this.magnitud = this.data.magnitud;
  }

  ngOnDestroy() {}

  guardarUnidad() {
    this.unit = {
      unidad: this.unidad,
      simbolo: this.simbolo,
      magnitud: this.magnitud
    };
    if (!this.updateAction) {
      this.message = 'Guardando documento...';
      this.openSnackBar();
      this.dataService
        .create(this.unit, 'unidadesMed')
        .then(() => {
          this.message = 'Documento Guardado!!!';
          this.openSnackBar();
          this.dialogRef.close();
        })
        .catch(error => {
          this.message = 'Error al guardar documento!!!';
          this.openSnackBar();
          console.log(error);
        });
    } else {
      this.actualizaUnidad();
    }
  }

  async actualizaUnidad() {
    if (this.updateAction) {
      this.message = 'Actualizando documento...';
      this.openSnackBar();
      try {
       await this.dataService.update(this.id, this.unit, 'unidadesMed')
       this.message = 'Documento Actualizado!!!';
       this.openSnackBar();
       this.dialogRef.close();
      } catch (error) {
        this.message = 'Error al actualizar documento!!!';
        this.openSnackBar();
        console.log(error);
      }
    }
  }

  async getMagnitudes() {
    this.magnitudes = await this.dataService.getAll('magnitudes');
  }

  compareMagnitud(o1: any, o2: any): boolean {
    return o1 && o2 && o1.id === o2.id;
  }

  openSnackBar() {
    this.snackBar.open(this.message, null, {
      duration: 1500
    });
  }
}
