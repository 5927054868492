// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyD7StNvvcqIkGsnC-Cq0NJuMc52GPicSWc',
    authDomain: 'project1-cced0.firebaseapp.com',
    databaseURL: 'https://project1-cced0.firebaseio.com',
    projectId: 'project1-cced0',
    storageBucket: 'project1-cced0.appspot.com',
    messagingSenderId: '885725482749',
    appId: '1:885725482749:web:4d03ee98cbe61f719cb894'
    },
  appVersion: require('../../package.json').version + '-dev',
  apiUrl: 'http://localhost:8080/api/',
  useServiceWorker: false
};
