import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from '@labqc-core/auth.service';
import { NavService } from '@labqc-services/nav.service';

import firebase from 'firebase/compat/app';
import { MatDialog } from '@angular/material/dialog';
import { UserButtonDialogComponent } from '@labqc-components/user-button-dialog/user-button-dialog.component';
import { User } from '@labqc-models/user.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  dialogStatus: boolean;
  dialogRef: any;
  successMessage: string;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    private messageService: MessageService,
    public authService: AuthService,
    public navService: NavService,
    private dialog: MatDialog,
    iconRegistry: MatIconRegistry,
     sanitizer: DomSanitizer
  ) {
      iconRegistry.addSvgIcon('home', sanitizer.bypassSecurityTrustResourceUrl('assets/img/svg/home-2.svg'));
      iconRegistry.addSvgIcon('catalogs', sanitizer.bypassSecurityTrustResourceUrl('assets/img/svg/briefcase-2.svg'));
      iconRegistry.addSvgIcon('reports', sanitizer.bypassSecurityTrustResourceUrl('assets/img/svg/pie-chart.svg'));
      iconRegistry.addSvgIcon('run-test', sanitizer.bypassSecurityTrustResourceUrl('assets/img/svg/play.svg'));
      iconRegistry.addSvgIcon('settings', sanitizer.bypassSecurityTrustResourceUrl('assets/img/svg/settings.svg'));
      iconRegistry.addSvgIcon('security', sanitizer.bypassSecurityTrustResourceUrl('assets/img/svg/shield-check.svg'));
  }

  ngOnInit() {
  }

  login() {
    this.router.navigate(['login']);
  }

  fireLogout() {
    this.afAuth.signOut();
    this.router.navigate(['/']);
    this.messageService.add({ severity: 'warn', summary: 'Cierre de Sesión', detail: 'Su sesión se ha cerrado' });
  }

  clear() {
    this.messageService.clear();
  }

  userMenu() {
    this.dialogStatus = !this.dialogStatus;
    if (this.dialogStatus) {
      this.dialogRef = this.dialog.open(UserButtonDialogComponent, {
        position: { top: '69px', right: '5px' },
        width: '295px',
        backdropClass: 'backdropUserMenu',
        panelClass: 'dialogUserMenu'
      });
      this.dialogStatus = true;
    } else {
      this.dialogRef.close();
    }
    this.dialogStatus = !this.dialogStatus;
  }

  closeMyMenu() {
    this.trigger.closeMenu();
  }

}
