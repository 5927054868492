import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  // Main task
  task: AngularFireUploadTask;
  // Progress monitoring
  percentage: Observable<number>;
  snapshot: Observable<any>;
  // Download URL
  downloadURL: Observable<string>;
  // State for dropzone CSS toggling
  isHovering: boolean;
  // Emits file name on completion
  @Input() afsCollection: string;
  @Input() afsDocPath: string;
  @Input() fileCategory: { };
  @Output() uploadCompleteEvent = new EventEmitter<any>();
  constructor(private storage: AngularFireStorage, private afs: AngularFirestore) { }

  ngOnInit() {
    if (this.afsCollection === null) {
      this.afsCollection = 'variousFiles';
    }
    if (this.fileCategory === null || this.fileCategory === undefined) {
      this.fileCategory = { catId: 'cat00', display: 'others' };
    }
     console.log(this.fileCategory);
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  startUpload(elTarget: any ) {
    const event: FileList = elTarget.files;
    console.log(event);
    if (event.length > 0) {
      // The File object
      const file = event.item(0);
      // Client-side validation example
      console.log(file);
      if ((file.type.split('/')[0] !== 'image') && file.type !== 'application/pdf') {
        console.error('unsupported file type :( ');
        return;
      }
      if (file.size > 5242880) {
        console.error('File too big :( ');
        alert('El máximo tamaño de archivo permitido es de 5 MB');
        return;
      }
      // The storage path
      const path = `${this.afsCollection}/${new Date().getTime()}_${file.name}`;
      // Totally optional metadata
      const customMetadata = { app: 'labqc-cloud' };
      // The main task
      this.task = this.storage.upload(path, file, { customMetadata });
      // Progress monitoring
      this.percentage = this.task.percentageChanges();
      this.snapshot = this.task.snapshotChanges();
      // .pipe(
      //   tap(snap => {
      //     if (snap.bytesTransferred === snap.totalBytes) {
      //       // Update firestore on completion
      //       this.afs.collection('filesUploaded').add({ path, size: snap.totalBytes });
      //     }
      //   })
      // );
      // // The file's download URL
      // this.downloadURL = this.task.downloadURL();
      this.task.then(snapshot => {
        console.log(snapshot);
        this.uploadCompleteEvent.emit(snapshot);
        this.afs.collection(`${this.afsDocPath}/filesUploaded`).add(
          {
            path,
            category: this.fileCategory,
            size: snapshot.totalBytes,
            downloadURL: snapshot.ref.getDownloadURL(),
            originalFileName: file.name,
            uploadDate: firebase.firestore.FieldValue.serverTimestamp()
          });
      });
    }
  }



  // Determines if the upload task is active
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

}
