import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DataService } from '@labqc-services/data.service';
import { ModelSearchComponent } from '../model-search/model-search.component';

@Component({
  selector: 'app-instrumentos-modal',
  templateUrl: './instrumentos-modal.component.html',
  styleUrls: ['./instrumentos-modal.component.scss']
})
export class InstrumentosModalComponent implements OnInit {
  selectedModel: any;
  message: string;
  updateAction: boolean;
  newInstrumentForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private dataService: DataService,
    private dialogRef: MatDialogRef<InstrumentosModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {
    this.createForm();
  }

  ngOnInit() {
    if (this.data) {
      this.updateAction = true;
      this.newInstrumentForm.patchValue(
        {
          modelo: this.data.modelo,
          codigoId: this.data.codigoId,
          serie: this.data.serie,
          ubicacion: this.data.ubicacion,
        });
      this.selectedModel = this.data.modelo;

    }
  }

  changed(event) {
    this.newInstrumentForm.patchValue(
      {
        status: { statusNombre: event.source.selected.viewValue }
      });
  }

  createForm() {
    this.newInstrumentForm = this.fb.group({
      modelo: this.fb.group({ // https://codecraft.tv/courses/angular/forms/model-driven/
        id: [''],
        nombre: [{ value: '', disabled: true }],
        marca: [''],
        descripcion: [{ value: '', disabled: true }],
      }),
      codigoId: [''],
      serie: [''],
      ubicacion: [''],
    });
  }

  onSubmit() {
    // Create New Instrumento
    if (!this.updateAction) {
      this.dataService.create(this.newInstrumentForm.getRawValue(), 'instrumentos').then(docRef => {
        this.message = 'Documento creado!!!';
        this.openSnackBar();
        const result = this.newInstrumentForm.getRawValue();
        result.id = docRef.id;
        this.dialogRef.close(result);
        this.newInstrumentForm.reset();
      },
        error => {
          console.log(error);
          this.message = 'Error al guardar documento!!!';
          this.openSnackBar();
        }
      );
    } else {
      // Update Existing Instrumento
      console.log(this.data);
      this.dataService.update(this.data.id, this.newInstrumentForm.getRawValue(), 'instrumentos').then(() => {
        this.message = 'Documento actualizado!!!';
        this.openSnackBar();
        const result = this.newInstrumentForm.getRawValue();
        result.id = this.data.id;
        this.dialogRef.close(result);
        this.newInstrumentForm.reset();
      },
        error => {
          console.log(error);
          this.message = 'Error al guardar documento!!!';
          this.openSnackBar();
        });
    }
  }

  searchModel() {
    const dialogRef = this.dialog.open(ModelSearchComponent, {
      // height: '768px',
      width: '1024px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.newInstrumentForm.patchValue({
          modelo: result
        });
        this.selectedModel = result;
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openSnackBar() {
    this.snackBar.open(this.message, null, {
      duration: 1500,
    });
  }
}
