import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '@labqc-services/data.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-model-search',
  templateUrl: './model-search.component.html',
  styleUrls: ['./model-search.component.scss']
})
export class ModelSearchComponent implements OnInit {
  modelos: any;
  selectedModel: any;
  loading: boolean;
  globalFilter: any;
  cols = [
    { field: 'marca.nombre', header: 'Marca' },
    { field: 'nombre', header: 'Modelo' },
    { field: 'descripcion', header: 'Descripción' }
  ];
  constructor(
    private dataService: DataService,
    private dialogRef: MatDialogRef<ModelSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data) {
      this.getModelosByPropiedadMedible();
    } else {
      this.getModelos();
    }
  }

  async getModelos() {
    this.modelos = await this.dataService.getAll('modelos');
  }

  async getModelosByPropiedadMedible() {
    const query = ref => ref.where(`propiedadesMedibles.${this.data.id}.id`, '==', this.data.id);
    this.modelos = await this.dataService.getAll('modelos', query);
  }

  resetFilters(dataTable) {
    dataTable.reset();
    this.globalFilter = null;
  }

  selectModel() {
    this.dialogRef.close(this.selectedModel);
  }
}
