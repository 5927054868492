import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { NavItem } from './nav-item';
import { Router } from '@angular/router';
import { NavService } from '@labqc-services/nav.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
// import { AuthService } from '@labqc-core/auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from '@labqc-core/auth.service';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuListItemComponent {
  expanded = false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem;
  @Input() depth: number;
  constructor(
    public navService: NavService,
    public router: Router,
    public afAuth: AngularFireAuth,
    public authService: AuthService,
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }



  onItemSelected(item: NavItem) {
    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]);
      this.navService.closeNav();
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }
}
