import { AbstractControl, FormGroup } from '@angular/forms';
export class TestValidation {


  static plPlus(AC: AbstractControl) {
    const t2Plus = AC.get('t2Plus').value;
    const plPlus = AC.get('plPlus').value;
    const valNominal = AC.get('valNominal').value;
    if (plPlus <= t2Plus) {
      AC.get('plPlus').setErrors({ plPlus: true });
    } else {
      return null;
    }
  }

  static t2Plus(AC: AbstractControl) {
    const plPlus = AC.get('plPlus').value;
    const t2Plus = AC.get('t2Plus').value;
    const t1Plus = AC.get('t1Plus').value;
    const valNominal = AC.get('valNominal').value;
    if (t2Plus <= t1Plus) {
      AC.get('t2Plus').setErrors({ t2Plus: true });
    } else {
      return null;
    }
  }

  static t1Plus(AC: AbstractControl) {
    const t2Plus = AC.get('t2Plus').value;
    const t1Plus = AC.get('t1Plus').value;
    const valNominal = AC.get('valNominal').value;
    if (t1Plus <= valNominal ) {
      AC.get('t1Plus').setErrors({ t1Plus: true });
    } else {
      return null;
    }
  }

  static t1Minus(AC: AbstractControl) {
    const t1Minus = AC.get('t1Minus').value;
    const t2Minus = AC.get('t2Minus').value;
    const valNominal = AC.get('valNominal').value;
    if (t1Minus >= valNominal) {
      AC.get('t1Minus').setErrors({ t1Minus: true });
    } else {
      return null;
    }
  }

  static t2Minus(AC: AbstractControl) {
    const valNominal = AC.get('valNominal').value;
    const t1Minus = AC.get('t1Minus').value;
    const t2Minus = AC.get('t2Minus').value;
    const plMinus = AC.get('plMinus').value;
    if (t2Minus >= t1Minus) {
      AC.get('t2Minus').setErrors({ t2Minus: true });
    } else {
      return null;
    }
  }

  static plMinus(AC: AbstractControl) {
    const valNominal = AC.get('valNominal').value;
    const t2Minus = AC.get('t2Minus').value;
    const plMinus = AC.get('plMinus').value;
    if (plMinus >= t2Minus) {
      AC.get('plMinus').setErrors({ plMinus: true });
    } else {
      return null;
    }
  }

}


// https://scotch.io/@ibrahimalsurkhi/match-password-validation-with-angular-2
