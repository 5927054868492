import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@labqc-core/auth.service';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {BehaviorSubject, Observable, firstValueFrom} from 'rxjs';
import { AppConfiguration, APP_CONFIG } from 'app/app-config.module';
import { environment } from 'environments/environment';
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

export type dbType = 'firestore' | 'pouchdb' | 'api'

@Injectable({
  providedIn: 'root'
})
export class DataService {
  currentUser: any;
  pouchDB;
  username: string;
  password: string;
  remote: string;
  apiUrl = environment.apiUrl;
  _dbType = new BehaviorSubject<dbType>('firestore');
  constructor(
    private http: HttpClient,
    private firestore: AngularFirestore,
    public authService: AuthService,
    @Inject(APP_CONFIG) private config: AppConfiguration,

  ) {
    console.log('Hello DbService');
    this.authService.user$.subscribe((user: any) => {
      console.log('user: ', user);
      this.currentUser = user;
    });
    this.initPouchDB();
  }


  get dbType(): dbType {
    return this._dbType.value;
  }

  set dbType(type: dbType){
    this._dbType.next(type);
  }

  private initPouchDB(): void {
    PouchDB.plugin(PouchFind);
    this.pouchDB = new PouchDB(this.config.pouchDB.dbNameMain);
    console.log('adapter: ', this.pouchDB.adapter);
    window.PouchDB = PouchDB;
    this.username = this.config.pouchDB.mainDbUser;
    this.password = this.config.pouchDB.mainDbPsw;
    this.remote = this.config.pouchDB.mainDbUrl;
    this.pouchDB.setMaxListeners(20);
    this.pouchDB.info().then(result => {
      console.log(result);
    });
    // this.replication();
    this.createIndexes();
  }

  createIndexes(){
    this.pouchDB.createIndex({
      index: {
        fields: ['type', 'testStartDate']
      }
    });

    this.pouchDB.getIndexes().then(function (result) {
      console.log(result);
    })
  }

  /**
   * Replicate The full Database
   */
  private replication(): void {
    const options = {
      live: true,
      retry: true,
      auth: {
        username: this.username,
        password: this.password
      }
    };
    this.pouchDB.replicate
      .from(this.remote, options)
      .on('change', change => {
        console.log('replication: change: ', change);
        if (change.pending === 0) {
          console.log('no more changes');
        }
      })
      .on('paused', err => {
        console.log('replication: paused: ', err);
      })
      .on('active', () => {
        console.log('replication: active: ');
      })
      .on('denied', err => {
        console.log('replication: denied): ', err);
      })
      .on('complete', info => {
        console.log('replication: complete: ', info);
      })
      .on('error', err => {
        console.log('replication: error: ', err);
      });
  }

  create(data: any, type?: string): Promise<any> {
    switch (this.dbType) {
      case 'firestore':
        return this.firestore.collection(type).add(data);
      case 'pouchdb':
        return this.pouchDB.put(data);
      case 'api':
        return firstValueFrom(this.http.post(`${this.apiUrl}/${type}`, data));
    }
  }

  read<T>(id: string, type?: string): Promise<T> {
    switch (this.dbType) {
      case 'firestore':
        return firstValueFrom(this.firestore.doc<T>(`${type}/${id}`).valueChanges({ idField: 'id' }));
      case 'pouchdb':
        return this.pouchDB.get(id);
      case 'api':
        return firstValueFrom(this.http.get<T>(`${this.apiUrl}/${type}/${id}`));
    }
  }

  getAll<T>(type: string, query?): Promise<T[]> {
    switch (this.dbType) {
      case 'firestore':
        return this.firestore.collection<T>(type, query).get().toPromise().then(snapshot => snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      case 'pouchdb':
        return this.pouchDB.find({
          selector: { type}, ...query
        }).then(result => result.docs);
      case 'api':
        return firstValueFrom(this.http.get<T[]>(`${this.apiUrl}/${type}`, { params: { type } }));
    }
  }

  update(id: string, data: any, type?: string): Promise<any> {
    if (this.currentUser) {
      data.lastModifiedBy = this.currentUser.uid;
    }
    switch (this.dbType) {
      case 'firestore':
        return this.firestore.doc(`${type}/${id}`).set(data, { merge: true }); // Replace .update() for .set(), investigate difference
      case 'pouchdb':
        return this.pouchDB.put({ ...data, _id: id, _rev: data._rev });
      case 'api':
        return firstValueFrom(this.http.patch(`${this.apiUrl}/${type}/${id}`, data));
    }
  }

  delete(id: string, type?: string): Promise<any> {
    switch (this.dbType) {
      case 'firestore':
        return this.firestore.doc(`${type}/${id}`).delete();
      case 'pouchdb':
        return this.pouchDB.get(id).then(doc => this.pouchDB.remove(doc));
      case 'api':
        return firstValueFrom(this.http.delete(`${this.apiUrl}/${type}/${id}`));
    }
  }

  deleteModelo(doc): Promise<void> {
    switch (this.dbType) {
      case 'firestore':
        const batch = this.firestore.firestore.batch();
        const modelosCollectionRef = this.firestore.firestore
          .collection('modelos')
          .doc(doc.id);
        const marcasSubCollectionRef = this.firestore.firestore
          .collection(`marcas/${doc.marcaId}/modelos`)
          .doc(doc.id);
        batch.delete(modelosCollectionRef);
        batch.delete(marcasSubCollectionRef);
        return batch.commit();
      case 'pouchdb':
        throw new Error("not yet implemented for pouchdb");
      case 'api':
        throw new Error("not yet implemented for api");
    }

  }

  /**
   * Retrieves a collection of documents from Cloud Firestore with its ids.
   * @param path Path to collection. Must be odd.
   * @param query Perform Query, Limit, OrderBy
   */
   collection$(path: string, query?): Observable<any[]> {
    return this.firestore.collection(path, query).valueChanges({ idField: 'id' });
  }

  public getJSON(): Observable<any> {
    return this.http.get('assets/metadata.json');
  }
}

