import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { DataService } from '@labqc-services/data.service';
import { environment } from 'environments/environment';
declare var Trianglify: any;

@Component({
  selector: 'app-front-page',
  templateUrl: './front-page.component.html',
  styleUrls: ['./front-page.component.scss']
})
export class FrontPageComponent implements OnInit, OnDestroy, AfterViewInit {
  // @ViewChild('trianglify') trianglifyCanvasRef: ElementRef;
  color = 'random'; // 'random'
  currentApplicationVersion = environment.appVersion;
  buildRevision: any;
  constructor(private elementRef: ElementRef, private dataService: DataService) { }

  ngOnInit() {
    // this.renderCanvas();
    this.dataService.getJSON().subscribe(res=>this.buildRevision = res.buildRevision);
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
  }

  // renderCanvas() {
  //   if (!this.elementRef.nativeElement.parentNode) { return; }
  //   const width = this.elementRef.nativeElement.children[0].offsetWidth;
  //   // const height = this.elementRef.nativeElement.children[0].offsetHeight;
  //   const height = this.elementRef.nativeElement.parentNode.offsetHeight;

  //   const pattern = Trianglify({
  //     cell_size: 30,
  //     variance: Math.random(),
  //     x_colors: this.color,
  //     // x_colors: 'Blues',
  //     y_colors: 'match_x',
  //     palette: Trianglify.colorbrewer,
  //     color_space: 'lab',
  //     color_function: false,
  //     stroke_width: 1.51,
  //     width: width,
  //     height: height,
  //     seed: Math.random()
  //   });
  //   pattern.canvas(this.trianglifyCanvasRef.nativeElement);
  // }
}
