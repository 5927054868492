import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import {User} from '@labqc-models/user.model';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<User>;
  permissions: any;
  currentUser: any;
  permissions$: any;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private http: HttpClient
  ) {
    // Get auth data, then get firestore user document || null
    this.user$ = this.afAuth.authState
      .pipe(
        switchMap(user => {
          if (user) {
            return (combineLatest([
              this.afs.doc<User>(`users/${user.uid}`).valueChanges(),
              this.afs.doc('permissions/userPermissions').valueChanges()]
              ))
              .pipe(
                  catchError(err => {
                    console.log(err.message);
                    return of([null, null]);
                  }),
                map(res => {
                  this.currentUser = res[0];
                  this.permissions = res[1];
                  return res[0];
                })
              );
          } else {
            this.currentUser = null;
            this.permissions = null;
            return of(null);
          }
        })
      );
  }


  getCurrentUser(): Promise<any> {
    return this.user$.pipe(take(1)).toPromise();
  }

  async deleteUserAccount() {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken(true);
      return this.http.post('https://ineedahand-app.firebaseapp.com/api/delete-user', { idToken }).toPromise();
    } catch (error) {
      alert(error);
    }
  }

  catchedUser() {
    const usr = localStorage.getItem('user');
    const storedUser = JSON.parse(usr);
    if (storedUser) {
      storedUser.lastLocationUpdate = this.createTimeStamp(
        storedUser.lastLocationUpdate
      );
      storedUser.lastModifiedAt = this.createTimeStamp(
        storedUser.lastModifiedAt
      );
      storedUser.lastWorkDate = this.createTimeStamp(storedUser.lastWorkDate);
      storedUser.signupDate = this.createTimeStamp(storedUser.signupDate);
      storedUser.userFormStorage = true;
    }
    return storedUser;
  }

  createTimeStamp(timestampJSObj: firebase.firestore.Timestamp) {
    return new firebase.firestore.Timestamp(
      timestampJSObj.seconds,
      timestampJSObj.nanoseconds
    );
  }

  ///// Login/Signup //////

  // googleLogin() {
  //   const provider = new firebase.auth.GoogleAuthProvider();
  //   return this.oAuthLogin(provider);
  // }

  // private oAuthLogin(provider) {
  //   return this.afAuth.auth.signInWithPopup(provider).then(credential => {
  //     this.updateUserData(credential.user);
  //   });
  // }

  loginWithEmail(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((credential) => {
        console.log(credential);
        this.updateUserData(credential.user);
      });
  }

  signOut() {
    this.afAuth.signOut();
    this.router.navigate(['']);
  }

  private updateUserData(user) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const data: User = {
      uid: user.uid,
      nombre: user.email,
      email: user.email,
      roles: {
        operator: true
      },
      lastLogin: firebase.firestore.FieldValue.serverTimestamp()
    };
    return userRef.set(data, { merge: true });
  }

  isOperator(): boolean {
    const allowed = ['admin', 'supervisor', 'operator'];
    return this.checkAuthorization(allowed);
  }

  isSupervisor(): boolean {
    const allowed = ['admin', 'supervisor'];
    return this.checkAuthorization(allowed);
  }

  isAdmin(): boolean {
    const allowed = ['admin'];
    return this.checkAuthorization(allowed);
  }

  canViewConfig(): boolean {
    const allowed = ['admin', 'supervisor'];
    return this.checkAuthorization(allowed);
  }

  canViewConfigMenu(): boolean {
    const allowed = ['admin', 'supervisor'];
    return this.checkAuthorization(allowed);
  }

  canViewSecurityMenu(): boolean {
    const allowed = ['admin', 'supervisor'];
    return this.checkAuthorization(allowed);
  }

  canViewAdminMenu(): boolean {
    const allowed = ['admin', ];
    return this.checkAuthorization(allowed);
  }

  canAccessElement(grantedRoles: string[]): boolean {
    return this.checkAuthorization(grantedRoles);
  }


  // determines if user has matching role
  private checkAuthorization(allowedRoles: string[]): boolean {
    if (!this.currentUser) { return false; }
    for (const role of allowedRoles) {
      if (this.currentUser.roles[role]) {
        return true;
      }
    }
    return false;
  }

  // https://devblogs.microsoft.com/premier-developer/angular-how-to-implement-role-based-security/
  hasPermission(elementId: string) {
    // const user = await this.getCurrentUser()
    if (!this.currentUser || !this.permissions) { return false; }
    if (this.permissions[elementId] && this.permissions[elementId].roles) {
      for (const role in this.permissions[elementId].roles) {
        if (Object.prototype.hasOwnProperty.call(this.permissions[elementId].roles, role)) {
          const currentRole = this.permissions[elementId].roles[role];
          if (this.currentUser.roles[role] && currentRole) {
            // console.warn('User Allowed');
            return true;
          }
        }
      }
      // console.warn('User not allowed');
      return false;
    }
    // console.warn('Roles not found');
    return false;
  }

  // This method is called once and a list of permissions is stored in the permissions property
  // initializePermissions() {
  //   this.permissions$ = this.afs.doc('permissions/userPermissions').valueChanges()
  //     .pipe(tap(val => {
  //       this.permissions = val;
  //     }));
  // }
}
