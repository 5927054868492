<div class="dropzone" dropZone (hovered)="toggleHover($event)" (dropped)="startUpload($event)" [class.hovering]="isHovering">
  <!-- <h3>Cargar archivo</h3> -->
  <!-- <p>Arrastra y suelta</p> -->
  <label for="file-upload" class="custom-file-upload">
    <i class="pi pi-cloud-upload"></i>&nbsp; Da click o arrastra archivos aqui para adjuntar ...
  </label>
  <input id="file-upload" type="file" accept=".jpg, .jpeg, .gif, .tif, .tiff, .png, .pdf" (change)="startUpload($event.target)" />

</div>
  <div *ngIf="percentage | async as pct" class="progress">
    <!-- <progress class="progress is-info" [value]="pct" max="100"></progress> -->
    <p-progressBar [value]="pct" [style]="{'height': '6px'}" [showValue]="false" ></p-progressBar>
    {{ pct | number }}%
    <div *ngIf="(snapshot | async) as snap">
      {{ snap.bytesTransferred | fileSize }} de {{ snap.totalBytes | fileSize }}
      <!-- <button mat-button (click)="task.pause()" class="button is-warning" [disabled]="!isActive(snap)">Pausar</button>
      <button mat-button (click)="task.cancel()" class="button is-danger" [disabled]="!isActive(snap)">Cancelar</button>
      <button mat-button (click)="task.resume()" class="button is-info" [disabled]="!(snap?.state === 'paused')">Reanudar</button> -->
    </div>
  </div>



<!-- <div *ngIf="downloadURL | async as url">
  <h3>Imagen</h3>
  <img [src]="url">
  <br>
  <a [href]="url" target="_blank" rel="noopener">Descargar</a>
</div>
<hr> -->
