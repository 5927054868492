<div class="header" #logo>
  <h2 class="title">
    LEMOTEK
  </h2>
  <h3 class="subtitle">
    Quality Control Software
  </h3>
  <div class="logo flex flex-col items-center">
    <img src="/assets/img/SmartLink_logo(wt).png" alt="SmartLink Logo" >
    <h5 class="version" style="color: white">v{{currentApplicationVersion}} Build: {{buildRevision}}</h5>
  </div>
  <br/>
</div>

<div class="buttons-container">

  <div class="btn1" [routerLink]="['/test-selection']">
    <div class="menu-button">
      <img class="button-icon" src="assets/img/svg/play.svg" alt="">
      <div class="btn-text">
        Correr Prueba
      </div>

    </div>

  </div>
  <div class="btn2" [routerLink]="['/tests']">
    <div class="menu-button">
      <div class="button-icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M12.5,2.5a9,9,0,0,1,9,9h-9Z" fill="none"/>
          <path d="M12.5,4.1H11a9,9,0,1,0,9,9,7.6,7.6,0,0,0-.1-1.5" fill="none"/></svg>
      </div>
      <div class="btn-text">
        Reportes
      </div>

    </div>
  </div>
<!--  <div class="btn3">-->
<!--    <div class="menu-button">-->
<!--      <mat-icon class="button-icon">cloud_off</mat-icon>-->
<!--      <div class="btn-text">-->
<!--        Captura Offline-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</div>
