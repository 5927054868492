import { NgModule, InjectionToken } from '@angular/core';

export const APP_CONFIG = new InjectionToken<AppConfiguration>('app.config');

export class AppConfiguration {
  googleProjectNumber: string;
  serialSettings:{
    baudRate: number;
    dataBits: number;
    parity: 'none'|'odd'|'even';
    stopBits: number;
    dtr: boolean;
    rts: boolean;
    sleepOnPause: boolean;
  }
  pouchDB: {
    dbNameMain: string;
    mainDbUser: string;
    mainDbPsw: string;
    mainDbUrl: string;
  }
}

export const APP_DI_CONFIG: AppConfiguration = {
  googleProjectNumber: '404205522174',
  serialSettings: {
    baudRate: 9600,
    dataBits: 8,
    parity: 'none',
    stopBits: 1,
    dtr: false,
    rts: false,
    sleepOnPause: true,
  },
  pouchDB: {
    dbNameMain: 'smartlink_dev',
    mainDbUser: 'admin',
    mainDbPsw: 'Password1',
    mainDbUrl: 'http://127.0.0.1:5984/smartlink_dev',
  }
};


@NgModule({
  providers: [{
    provide: APP_CONFIG,
    useValue: APP_DI_CONFIG
  }]
})
export class AppConfigModule { }


// https://stackoverflow.com/a/43193574/7944538
