import { Component, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NavService } from '@labqc-services/nav.service';
import { navItems } from '@labqc-components/menu-list-item/nav-items';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@labqc-core/auth.service';
import { first } from 'rxjs/operators';
import { User } from '@labqc-models/user.model';
import { UpdateService } from '@labqc-services/update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'app';
    // Fill the side menu
    navItems = navItems;
    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    @ViewChild('appDrawer', { static: true }) appDrawer: ElementRef;
    idleTimeInSec: number;
    timeoutInSec: number;
    constructor(
      // public auth: AuthService,
      public afAuth: AngularFireAuth,
      public router: Router,
      private messageService: MessageService,
      private navService: NavService,
      private idle: Idle,
      private keepalive: Keepalive,
      private confirmationService: ConfirmationService,
      private dialogRef: MatDialog,
      private authService: AuthService,
      private sw: UpdateService
    ) {
      this.idleTimeInSec = 600;
      this.timeoutInSec = 15;
      // sets an idle timeout of 600 seconds (10 mins).
      idle.setIdle(this.idleTimeInSec);
      // sets a timeout period of 5 seconds. after 600 + 15 seconds of inactivity, the user will be considered timed out.
      idle.setTimeout(this.timeoutInSec);
      // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
      idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';
        this.timedOut = true;
        this.confirmationService.close();
        this.fireLogout();
      });
      // idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
      idle.onTimeoutWarning.subscribe((countdown) => {
        this.idleState = 'Su sesión está por finalizar.';
        this.confirm();
      });

      // sets the ping interval to 15 seconds
      keepalive.interval(15);

      keepalive.onPing.subscribe(() => this.lastPing = new Date());

      this.reset();

      this.afAuth.authState.subscribe(user => {
        if (user) {
          idle.watch();
          this.timedOut = false;
        } else {
          idle.stop();
        }
      });
      this.authService.user$.pipe(first()).subscribe((user: User) => {
        if (user && user.lastLogin.toDate().getTime() < (new Date().getTime() - this.idleTimeInSec * 1000) ) {
          this.fireLogout();
        }
      });
      this.sw.checkForUpdates();
      this.router.navigate(['']);
    }

  ngAfterViewInit() {
    this.navService.appDrawer = this.appDrawer;
  }

  login() {
    this.router.navigate(['login']);
    this.navService.closeNav();
  }

  // Firebase logout
  fireLogout() {
    this.dialogRef.closeAll();
    this.router.navigate(['/']);
    this.afAuth.signOut();
    this.messageService.add({ severity: 'warn', summary: 'Cierre de Sesión', detail: 'Su sesión se ha cerrado' });
    // this.successMessage = 'Su sesión se ha cerrado';
    // this._success.debounceTime(2000).subscribe(() => this.successMessage = null);
    // this._success.next();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'iniciado.';
    this.timedOut = false;
  }

  confirm() {
    this.confirmationService.confirm({
        message: `${this.idleState}`,
        header: 'Confirmar',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Cerrar Sesión',
        rejectLabel: 'Continuar',
        accept: () => {
          this.fireLogout();
        },
        reject: () => {
          this.reset();
        }
    });
  }

}
