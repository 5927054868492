<button mat-raised-button color="accent" (click)="goBack()">Regresar</button>

<h1 *ngIf="equipos.length > 0">Modelos Marca {{equipos[0].marca.nombre}}</h1>


<p-table #tt [value]="equipos" [columns]="cols" dataKey="id" [loading]="loading" [rowHover]="true"
  [rows]="50" [paginator]="true" selectionMode="single" [style]="{'font-size': '.8rem'}" sortField="marca.nombre">
  <ng-template pTemplate="caption">
      <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
      <input type="text" pInputText class="p-inputtext-sm" size="50" placeholder="Filtro Global" (input)="tt.filterGlobal($event.target.value, 'contains')"
        style="width:auto">
      <button type="button" pButton class="p-button-outlined p-button-sm" (click)="resetFilters(tt)" label="Restablecer Filtros"></button>
      <div detail style="float:right">
        <button type="button" pButton class="p-button-outlined p-button-sm" icon="fa fa-file" iconPos="left" label="Exportar filtrados" (click)="tt.exportCSV()" ></button>
        <button type="button" pButton class="p-button-outlined p-button-sm" icon="fa fa-file" iconPos="left" label="Exportar Selection" (click)="tt.exportCSV({selectionOnly:true})"></button>
      </div>
    </ng-template>
  <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{col.header}}</th>
        <th style="width:45%">Parámetros</th>
        <!-- <th style="width: 5%">Editar</th> -->
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData" >
        <td>{{rowData.marca.nombre}}</td>
        <td>{{rowData.nombre}}</td>
        <td>{{rowData.descripcion}}</td>
        <td>
          <tr *ngFor="let p of rowData.parametrosMed">
            <td>
              <i>{{p.nombre}}: </i>
              <small>[Alcance]</small> {{p.alcanceInferior}}{{p.unidadMed.simbolo}} a {{p.alcanceSuperior}}{{p.unidadMed.simbolo}},&nbsp;&nbsp;</td>
            <td>
              <small>[Resolución]</small> {{p.resolucion}}{{p.unidadMed.simbolo}},&nbsp;&nbsp; </td>
            <td>
              <small>[Error Max]</small> &plusmn; &nbsp;{{p.errorMax}}{{p.unidadMed.simbolo}}</td>
          </tr>
        </td>
        <!-- <td>
          <button pButton class="p-button-outlined p-button-sm" type="button" icon="fa fa-pencil" (click)="editModel(rowData)"></button>
        </td> -->
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length + 1">
                No se encontraron registros
            </td>
        </tr>
    </ng-template>
  </p-table>
