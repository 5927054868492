import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { UnidadMed } from '@labqc-models/modelo.model';

import { Observable } from 'rxjs';
import { DataService } from '@labqc-services/data.service';

@Component({
  selector: 'app-propiedad-add-edit',
  templateUrl: './propiedad-add-edit.component.html',
  styleUrls: ['./propiedad-add-edit.component.scss']
})
export class PropiedadAddEditComponent implements OnInit {
  units: any[];
  propsMedibles: Promise<any>;
  magnitudes: any;
  magnitud: any;
  unidadesMed: any[any];
  unidadMed: any;
  unidades = [];
  alcanceInf: number;
  alcanceSup: number;

  propiedadMedible: any;

  indexValor: number;
  indexUnidad: number;
  indexTipo: number = null;
  stringTipo = '';
  arrayLength: number = null;
  updateAction: boolean;

  constructor(
    private dataService: DataService,
    public dialogRef: MatDialogRef<PropiedadAddEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.getPropsMedicion();
    this.getUnidadesMed();
  }

  ngOnInit() {
    if (this.data) {
      this.fillUpdateForm();
    }
  }

  getPropsMedicion() {
    const query =  ref => ref.orderBy('nombre');
    this.propsMedibles = this.dataService.getAll('propsMedibles',query);
  }

  async getUnidadesMed(): Promise<any>{
      const unidades = await this.dataService.getAll<UnidadMed>('unidadesMed');
      this.unidadesMed = unidades;
      // Funcion para remover duplicados de un arreglo de objetos basado en una propiedad. (not working, but why?) http://bit.ly/2H1RlrL
      return _
        .chain(this.unidadesMed)
        .uniqBy('magnitud.id')
        .sortBy('magnitud.nombre')
        .value();
  }

  fillUpdateForm() {
    this.updateAction = true;
    this.propiedadMedible = {...this.data};
    this.getUnidadesMed().then(() => {
      this.onSelectPropiedad();
    });
    this.magnitud = this.propiedadMedible.magnitud;
    this.unidadMed = this.propiedadMedible.unidadMed;
    this.alcanceInf = this.propiedadMedible.alcanceInferior;
    this.alcanceSup = this.propiedadMedible.alcanceSuperior;

    this.indexValor = this.propiedadMedible.indexValor;
    this.indexUnidad = this.propiedadMedible.indexUnidad;
    this.indexTipo = this.propiedadMedible.indexTipo;
    this.stringTipo = this.propiedadMedible.stringTipo;
    this.arrayLength = this.propiedadMedible.arrayLength;
  }

  submit() {
    this.propiedadMedible.unidadMed = this.unidadMed;
    this.propiedadMedible.indexValor = this.indexValor;
    this.propiedadMedible.indexUnidad = this.indexUnidad;
    this.propiedadMedible.indexTipo = this.indexTipo || null;
    this.propiedadMedible.stringTipo = this.stringTipo || null;
    this.propiedadMedible.arrayLength = this.arrayLength || null;
    this.propiedadMedible.alcanceInferior = this.alcanceInf;
    this.propiedadMedible.alcanceSuperior = this.alcanceSup;
    this.dialogRef.close(this.propiedadMedible);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onSelectPropiedad() {
    this.magnitud = this.propiedadMedible.magnitud;
    this.unidades = this.unidadesMed.filter(
      unidad => unidad.magnitud.nombre === this.magnitud.nombre
    );
  }

  compareProp(o1: any, o2: any) {
    if (o1 && o2 && o1.id === o2.id) {
      return true;
    } else {
      return false;
    }
  }
}
