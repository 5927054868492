<div  class="container mx-auto">
  <form [formGroup]="newTestForm" (ngSubmit)="submit()" novalidate>
    <mat-card>
      <mat-card-header>
        <mat-card-title>Pruebas</mat-card-title>
        <mat-card-subtitle *ngIf="!updateAction">Agregar nueva prueba</mat-card-subtitle>
        <mat-card-subtitle *ngIf="updateAction">Modificar prueba</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>

        <div class="grid-container">

          <mat-form-field class="form-field">
            <input formControlName="method" matInput name="method" type="text" placeholder="Método" required>
            <mat-error *ngIf="formErrors.method">{{ formErrors.method}}</mat-error>
          </mat-form-field>

          <!-- <div class="column-one"> -->
          <mat-form-field class="form-field producto">
            <mat-select formControlName="producto" name="producto" placeholder="Producto" required (selectionChange)="onSelectProducto()"
              [compareWith]="compareProduct">
              <mat-option *ngFor="let prod of productos$ | async" [value]="prod">
                {{prod.productName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formErrors.producto">{{ formErrors.producto}}</mat-error>
          </mat-form-field>

          <mat-form-field class="form-field val-nominal">
            <input formControlName="valNominal" matInput name="valNominal" type="number" placeholder="Valor Nominal" required>
            <span matSuffix>{{unidadMed?.simbolo}}</span>
            <mat-error *ngIf="formErrors.valNominal">{{ formErrors.valNominal}}</mat-error>
          </mat-form-field>

          <mat-form-field class="form-field propiedad">
            <mat-select formControlName="propiedad" name="propiedad" placeholder="Propiedad Medible" required (selectionChange)="onSelectPropiedad($event)"
              [compareWith]="compareProp">
              <mat-option *ngFor="let prop of propsMedibles$ | async" [value]="prop">
                {{prop.nombre}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formErrors.propiedad">{{ formErrors.propiedad}}</mat-error>
          </mat-form-field>


          <mat-form-field class="form-field unidadMed">
            <mat-select formControlName="unidadMed" name="unidadMed" placeholder="Unidades" required (selectionChange)="onSelectUnidadMed($event)"
              [compareWith]="compareUnidad">
              <mat-option *ngFor="let unidad of unidades" [value]="unidad">
                {{unidad.unidad}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formErrors.unidadMed">{{ formErrors.unidadMed}}</mat-error>
          </mat-form-field>

          <mat-form-field class="form-field numMuestras">
            <input formControlName="numMuestras" matInput name="numMuestras" type="number" placeholder="Número de muestras" required>
            <mat-error *ngIf="formErrors.numMuestras">{{ formErrors.numMuestras}}</mat-error>
          </mat-form-field>

          <!-- <mat-form-field class="form-field modelInput">
            <input formControlName="modelo" readonly="true" matInput placeholder="Buscar modelo de equipo..." type="text" id="modelo"
              name="modelo" [value]="modelInput" (click)="searchModel()">
            <button mat-button *ngIf="!modelInput" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="searchModel()">
              <mat-icon matSuffix>search</mat-icon>
            </button>
            <button mat-button *ngIf="modelInput" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clearModelInput()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="formErrors.modelo">{{ formErrors.modelo}}</mat-error>
          </mat-form-field> -->

          <!-- </div> -->

          <mat-form-field class="form-field t1Minus">
            <input formControlName="t1Minus" matInput name="t1Minus" type="number" placeholder="- T1" required>
            <span matSuffix>{{unidadMed?.simbolo}}</span>
            <mat-error *ngIf="formErrors.t1Minus">{{ formErrors.t1Minus}}</mat-error>
          </mat-form-field>

          <mat-form-field class="form-field t1Plus">
            <input formControlName="t1Plus" matInput name="t1Plus" type="number" placeholder="+ T1" required>
            <span matSuffix>{{unidadMed?.simbolo}}</span>
            <mat-error *ngIf="formErrors.t1Plus">{{ formErrors.t1Plus}}</mat-error>
          </mat-form-field>


          <mat-form-field class="form-field t2Minus">
            <input formControlName="t2Minus" matInput name="t2Minus" type="number" placeholder="- T2" required>
            <span matSuffix>{{unidadMed?.simbolo}}</span>
            <mat-error *ngIf="formErrors.t2Minus">{{ formErrors.t2Minus}}</mat-error>
          </mat-form-field>

          <mat-form-field class="form-field t2Plus">
            <!-- <span matPrefix>&plusmn; &nbsp;</span> -->
            <input formControlName="t2Plus" matInput name="t2Plus" type="number" placeholder="+ T2" required>
            <span matSuffix>{{unidadMed?.simbolo}}</span>
            <mat-error *ngIf="formErrors.t2Plus">{{ formErrors.t2Plus}}</mat-error>
          </mat-form-field>


          <mat-form-field class="form-field plMinus">
            <input formControlName="plMinus" matInput name="plMinus" type="number" placeholder="- PL" required>
            <span matSuffix>{{unidadMed?.simbolo}}</span>
            <mat-error *ngIf="formErrors.plMinus">{{ formErrors.plMinus}}</mat-error>
          </mat-form-field>

          <mat-form-field class="form-field plPlus">
            <!-- <span matPrefix>&plusmn; &nbsp;</span> -->
            <input formControlName="plPlus" matInput name="plPlus" type="number" placeholder="+ PL" required>
            <span matSuffix>{{unidadMed?.simbolo}}</span>
            <mat-error *ngIf="formErrors.plPlus">{{ formErrors.plPlus}}</mat-error>
          </mat-form-field>

          <!-- <input [ngModel]="inputValue" name="option" (ngModelChange)="modelChanged($event)" [ngModelOptions]="{standalone: true}">

          <mat-form-field class="form-field">
            <mat-select name="vista" [(ngModel)]="vista" required (selectionChange)="onSelectVista($event)" [compareWith]="compareVista" [ngModelOptions]="{standalone: true}">
              <mat-option *ngFor="let vista of vistas" [value]="vista">
                {{vista.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <p>{{vista  | json}}</p>
          <p>{{inputValue}}</p> -->

          <button mat-raised-button type="button" (click)="convertToPercentage()" color="primary" *ngIf="!updateAction">Valores en %</button>


        </div>
        <!-- <p>Form value: {{ newTestForm.getRawValue() | json }}</p>
        <p>Form status: {{ newTestForm.status | json }}</p> -->

      </mat-card-content>
      <mat-card-actions align="start">
        <button mat-raised-button type="submit" color="primary" [disabled]="!newTestForm.valid" *ngIf="!updateAction">Enviar</button>
        <button mat-raised-button type="submit" color="accent" [disabled]="!newTestForm.valid" *ngIf="updateAction">Actualizar</button>
        <button mat-raised-button type="button" (click)="closeDialog()">Cerrar</button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
