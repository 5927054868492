<form [formGroup]="newInstrumentForm" novalidate (ngSubmit)="onSubmit()">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Instrumentos</mat-card-title>
      <mat-card-subtitle *ngIf="!updateAction">Agregar</mat-card-subtitle>
      <mat-card-subtitle *ngIf="updateAction">Editar</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="grid-container">

      <mat-form-field (click)="searchModel()" formGroupName="modelo" appearance="outline">
        <mat-label>Modelo</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput formControlName="nombre" type="text" placeholder="Modelo" required>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Identificación Interna</mat-label>
        <input matInput formControlName="codigoId" type="text" placeholder="Identificación Interna" required>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Serie</mat-label>
        <input matInput formControlName="serie" type="text" placeholder="Serie" required>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Ubicación</mat-label>
        <input matInput formControlName="ubicacion" type="text" placeholder="Ubicación">
      </mat-form-field>

    </mat-card-content>
    <mat-card-actions align="start">
      <button mat-raised-button color="primary" type="submit" [disabled]="!newInstrumentForm.valid || !selectedModel">Enviar</button>
      <button mat-raised-button color="accent" type="button" (click)="closeDialog()">Cerrar</button>
    </mat-card-actions>
  </mat-card>
</form>
