import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { DataService } from '@labqc-services/data.service';
import { ConfirmationService, MessageService} from 'primeng/api';

import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-marca-detail',
  templateUrl: './marca-detail.component.html',
  styleUrls: ['./marca-detail.component.scss']
})
export class MarcaDetailComponent implements OnInit {
  globalFilter: any;
  equipos = [];
  loading: boolean;
  cols = [
    { field: 'marca.nombre', header: 'Marca' },
    { field: 'nombre', header: 'Modelo' },
    { field: 'descripcion', header: 'Descripción' }
  ];
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private dataService: DataService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  async ngOnInit() {
    // // Return Full Subcollection, not a Query.
    // this.route.params
    //   .switchMap((params: Params) => this.dataService.getAfsCollectionMetaQuery('marcas', params.id))
    //   .subscribe(equipos => {
    //     this.equipos = equipos;
    //   }
    //   );

    const idParam = this.route.snapshot.paramMap.get('id');
    const query = ref => ref.where('marca.id', '==', idParam);
    this.equipos = await this.dataService.getAll('modelos', query);
  }

  goBack(): void {
    this.location.back();
  }

  deleteDocument(doc) {
    this.confirmationService.confirm({
      message:
        'Estás seguro que deseas eliminar el documento con ID "' +
        doc.id +
        '"?',
      header: 'Confirmación',
      icon: 'fa fa-question-circle',
      accept: () => {
        this.dataService
          .deleteModelo(doc)
          .then(x => {
            this.messageService.add({
              severity: 'success',
              summary: 'Eliminado',
              detail: 'Se eliminó documento'
            });
          })
          .catch(err => {
            console.log('deleteDocument: %s', err);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'No se pudo eliminar el documento'
            });
          });
      }
    });
  }

  resetFilters(dataTable) {
    dataTable.reset();
    this.globalFilter = null;
  }
}
