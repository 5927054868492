import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(public swUpdate: SwUpdate, private confirmationService: ConfirmationService, private messageService: MessageService) {
    if (swUpdate.isEnabled) {
      interval(1 * 60 * 1000).subscribe(() => swUpdate.checkForUpdate()
        .then(() => console.log('checking for updates')));
    } else {
      console.log('SwUpdate not enabled')
    }
    swUpdate.unrecoverable.subscribe(event => {
      alert(
        `An error occurred that we cannot recover from:\n${event.reason}\n\n` +
        'Please reload the page.');
    });
  }

  public checkForUpdates(): void {
    this.swUpdate.available.subscribe(event => this.promptUser());
  }

  private promptUser(): void {
    this.confirmationService.confirm({
      message: 'Nueva versión disponible. Cargarla ahora?',
      header: 'Confirmar',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sí',
      accept: () => {
        this.swUpdate.activateUpdate().then(() => document.location.reload());
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ severity: 'warn', summary: 'Rejected', detail: 'La nueva versión estará disponible al recargar la aplicación' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'La nueva versión estará disponible al recargar la aplicación' });
            break;
        }
      }
    });
  }
}
