<div class="popup-model-header">
  <div class="topDivUserPhoto" mat-button>
    <img class="profileIcon" src="assets/img/user-thumbnail.png" />
    <div *ngIf="auth.user$ | async as user" class="user-info">
      <div>{{ user.nombre }}</div>
      <div>{{ user.email }}</div>
    </div>
  </div>
</div>

<div class="popup-model-content">


</div>

<div class="popup-model-footer" align="end">
  <button mat-button *ngIf="(afAuth.authState | async)" (click)=fireLogout()>Cerrar Sesión</button>
</div>
