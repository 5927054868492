<div class="wrap">
  <div *ngIf="error" class="alert alert-warning" role="alert">
    <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
    <span class="sr-only">Error:</span> {{error}}
  </div>

  <div class="loginmodal-container">

    <h2>Inicia sesión</h2>
    <br>
    <form [formGroup]="userForm" (ngSubmit)="loginWithEmail($event, usuario.value, password.value)" autocomplete="off">

      <mat-form-field class="full-width">
        <input #usuario type="text" id="usuario" matInput class="form-control" placeholder="Correo electrónico" formControlName="usuario"
          required autocomplete="off">
      </mat-form-field>

      <div *ngIf="formErrors.usuario" class="alert-message">
        {{ formErrors.usuario }}
      </div>

      <mat-form-field class="full-width">
        <input #password type="password" id="password" matInput class="form-control" placeholder="Contraseña" formControlName="password"
          required autocomplete="off">
      </mat-form-field>

      <div *ngIf="formErrors.password" class="alert-message">
        {{ formErrors.password }}
      </div>

      <br>
      <button class="btn btn-md btn-primary full-width" mat-raised-button color="primary" type="submit" [disabled]="!userForm.valid">Iniciar Sesión</button>
      <hr>
    </form>
  </div>

</div>
