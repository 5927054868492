<a mat-list-item  *ngIf="(afAuth.authState | async) && authService.canAccessElement(item.canViewItem)" [ngStyle]="{'padding-left': (depth * 12) + 'px'}" (click)="onItemSelected(item)" [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}" [disabled]="!(authService.canAccessElement(item.enabled))">
  <mat-icon class="routeIcon">{{item.iconName}}</mat-icon>
  <div class="link-name">{{item.displayName}}</div>
  <span fxFlex *ngIf="item.children && item.children.length" class="caret-icon">
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
      expand_more
    </mat-icon>
  </span>
</a>
<div *ngIf="expanded" class="children-items">
  <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1" [disabled]="!(authService.canViewConfigMenu())">
  </app-menu-list-item>
</div>

