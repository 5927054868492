import { AuthService } from '@labqc-core/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  validationMessages:
    {
      'usuario': { 'required': string; 'pattern': string; };
      'password': { 'required': string; 'pattern': string; 'minlength': string; 'maxlength': string; };
    };
  formErrors: { 'usuario': string; 'password': string; };
  public error: any;
  passReset = false;
  userForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, public authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.userForm = this.fb.group({
      'usuario': ['', [
        Validators.required,
        Validators.pattern('^[^ ]+$'),
      ]
      ],
      'password': ['', [
        Validators.required,
        // Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
        Validators.minLength(6),
        Validators.maxLength(25)
      ]
      ],
    });

    this.userForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // reset validation messages
  }

  // Updates validation state on form changes.
  onValueChanged(data?: any) {
    this.formErrors = {
      'usuario': '',
      'password': ''
    };

    this.validationMessages = {
      'usuario': {
        'required': 'El usuario es obligatorio.',
        'pattern': 'El usuario no es válido.'
      },
      'password': {
        'required': 'Se requiere una contraseña.',
        'pattern': 'La contraseña debe incluir al menos una letra y un número.',
        'minlength': 'La contraseña debe contener al menos 6 caracteres.',
        'maxlength': 'La contraseña no puede exceder los 25 caracteres',
      }
    };
    if (!this.userForm) { return; }
    const form = this.userForm;
    // tslint:disable-next-line:forin
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        // tslint:disable-next-line:forin
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }

  }

  loginWithEmail(event, usuario, password) {
    event.preventDefault();
    usuario = usuario.toLowerCase();
    if (usuario.indexOf('@') === -1) {
      usuario = usuario + '@grupodatel.com.mx';
    }
    this.authService.loginWithEmail(usuario, password).then(() => {
      this.router.navigate(['home']);
    })
      .catch((error: any) => {
        if (error) {
          this.error = error;
          console.log(this.error);
        }
      });
  }



}
