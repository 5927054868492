import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '@labqc-services/data.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { of as observableOf, combineLatest as observableCombineLatest} from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-instrumentos-search',
  templateUrl: './instrumentos-search.component.html',
  styleUrls: ['./instrumentos-search.component.scss']
})
export class InstrumentosSearchComponent implements OnInit {
  loading = true;
  // instrumentos: {}[];
  instrumentos: any;
  globalFilter: any;
  selectedRow: any;
  cols = [
    { field: 'modelo.nombre', header: 'Modelo' },
    { field: 'modelo.descripcion', header: 'Descripción' },
    { field: 'serie', header: 'Serie' },
    { field: 'codigoId', header: 'ID Interna' },
    { field: 'ubicacion', header: 'Ubicación' },
  ];

  constructor(
    private dataService: DataService,
    public dialogRef: MatDialogRef<InstrumentosSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private afs: AngularFirestore
  ) {}

  ngOnInit() {

    if (this.data && this.data !== '') {
      console.log(this.data);
      this.getInstrumentosColletion(this.data.idOrSerial);
    } else {
      this.getInstrumentosColletion();
    }
  }

  async getInstrumentosColletion(idOrSerial?) {
    if (idOrSerial != null) {
      // https://stackoverflow.com/a/46786193/7944538
      const strSearch = idOrSerial;
      const strlength = strSearch.length;
      const strFrontCode = strSearch.slice(0, strlength - 1);
      const strEndCode = strSearch.slice(strlength - 1, strSearch.length);
      const startcode = strSearch;
      const endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

      // https://medium.com/google-developer-experts/performing-or-queries-in-firebase-cloud-firestore-for-javascript-with-rxjs-c361671b201e
      const codigoRef = this.afs.collection('instrumentos', ref => ref.where('codigoId', '>=', startcode).where('codigoId', '<', endcode))
        .snapshotChanges()
        .pipe(
          map((actions: any) => {
            return actions.map((a: any) => {
              const data = a.payload.doc.data();
              const id = a.payload.doc.id;
              return { id, ...data };
            });
          })
        );
      const serieRef = this.afs.collection('instrumentos', ref => ref.where('serie', '>=', startcode).where('serie', '<', endcode))
        .snapshotChanges()
        .pipe(
          map((actions: any) => {
            return actions.map((a: any) => {
              const data = a.payload.doc.data();
              const id = a.payload.doc.id;
              return { id, ...data };
            });
          })
        );

      const result$ = observableCombineLatest(codigoRef, serieRef).pipe(
        switchMap(instrumentos => {
          const [codigos, series] = instrumentos;
          const combined = codigos.concat(series);
          return observableOf(combined);
        })
      );
      result$.subscribe(res => {
        this.instrumentos = res;
        this.loading = false;
      });
    } else {
      this.instrumentos = await this.dataService.getAll('instrumentos')
      this.loading = false;
    }

    // if (cliente != null) {
    //   this.dataService.queryDocs('instrumentos', 'company.id', cliente).subscribe(instrumentos => {
    //     this.instrumentos = instrumentos;
    //     this.loading = false;
    //   });
    // } else {
    //   this.dataService.getColletion('instrumentos').subscribe(instrumentos => {
    //     this.instrumentos = instrumentos;
    //     this.loading = false;
    //   });
    // }
  }

  resetFilters(dataTable) {
    dataTable.reset();
    this.globalFilter = null;
  }

  selectInstrument() {
    this.dialogRef.close(this.selectedRow);
  }
}
