import { Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatButton } from '@angular/material/button';
import { AuthService } from '@labqc-core/auth.service';
import { of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Directive({
  selector: '[authorization]'
})
export class AuthorizationDirective implements OnInit, OnDestroy {
  @Input('authorization') hideElement: boolean; // Set this to explicitly hide the disabled element if no permission, otherwise it's just disabled.
  @HostBinding('disabled') isDisabled: boolean | undefined;
  userSubs: Subscription;
  permissionsSubs: Subscription;
  constructor(private el: ElementRef, private angularFireAuth: AngularFireAuth, private authService: AuthService, @Self() @Optional() private matbutton: MatButton) {
  }

  // Disable mat-button using directive
  // https://www.gitmemory.com/issue/angular/components/20876/716128459
  // https://stackblitz.com/edit/angular10-ivy-mat-nyodxk?file=src%2Fapp%2Fcommand.directive.ts
  // https://devblogs.microsoft.com/premier-developer/angular-how-to-implement-role-based-security/
  ngOnInit(): void {
    this.userSubs = this.angularFireAuth.authState
      .pipe(
        catchError(err => {
          console.log(err);
          return of(null);
        })
      )
      .subscribe(() => {
        this.checkPermissions();
      }, error => console.log(error));
  }

  ngOnDestroy(): void {
    this.userSubs.unsubscribe();
  }

  checkPermissions() {
    const hasPermission = this.authService.hasPermission(this.el.nativeElement.id);
    this.isDisabled = !hasPermission;
    this.el.nativeElement.disabled = !hasPermission;
    if (this.matbutton) {
      this.matbutton.disabled = !hasPermission;
    }

    if (!hasPermission) {
      if (this.hideElement) {
        this.el.nativeElement.style.display = 'none';
      } else {
        this.el.nativeElement.style.display = 'unset';
      }
    } else {
      this.el.nativeElement.style.display = 'unset';
    }
  }
}
