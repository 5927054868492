import { NavItem } from './nav-item';

export const navItems: NavItem[] = [
  {
    displayName: 'Inicio',
    iconName: 'home',
    canViewItem: ['admin','supervisor','operator'],
    enabled: ['admin','supervisor','operator'],
    route: '/'
  },
  {
    displayName: 'Correr Prueba',
    iconName: 'blur_on',
    canViewItem: ['admin','supervisor','operator'],
    enabled: ['admin','supervisor','operator'],
    route: '/test-selection'
  },
  {
    displayName: 'Reportes',
    iconName: 'blur_on',
    canViewItem: ['admin','supervisor','operator'],
    enabled: ['admin','supervisor','operator'],
    children: [
      {
        displayName: 'Pruebas',
        iconName: 'chevron_right',
        canViewItem: ['admin','supervisor','operator'],
        enabled: ['admin','supervisor','operator'],
        route: 'tests'
      },
      // {
      //   displayName: 'Métodos',
      //   iconName: 'chevron_right',
      //   canViewItem: ['admin','supervisor','operator'],
      //   enabled: ['admin'],
      //   route: '/'
      //
      // },
      // {
      //   displayName: 'Productos',
      //   iconName: 'chevron_right',
      //   canViewItem: ['admin','supervisor','operator'],
      //   enabled: ['admin'],
      //   route: '/'
      // }
    ]
  },
  {
    displayName: 'Configuración',
    iconName: 'blur_on',
    canViewItem: ['admin','supervisor','operator'],
    enabled: ['admin','supervisor'],
    children: [
      {
        displayName: 'Métodos',
        iconName: 'chevron_right',
        canViewItem: ['admin','supervisor','operator'],
        enabled: ['admin','supervisor'],
        route: 'tests-catalog'
      },
      {
        displayName: 'Productos',
        iconName: 'chevron_right',
        canViewItem: ['admin','supervisor','operator'],
        enabled: ['admin','supervisor'],
        route: 'products'
      },
      {
        displayName: 'Tipos de producto',
        iconName: 'chevron_right',
        canViewItem: ['admin','supervisor','operator'],
        enabled: ['admin','supervisor'],
        route: 'product-types'
      },
      {
        displayName: 'Instrumentos',
        iconName: 'chevron_right',
        canViewItem: ['admin','supervisor','operator'],
        enabled: ['admin','supervisor'],
        route: 'instrumentos'
      }
    ]
  },
  {
    displayName: 'Seguridad',
    iconName: 'blur_on',
    canViewItem: ['admin','supervisor','operator'],
    enabled: ['admin','supervisor'],
    children: [
      {
        displayName: 'Usuarios',
        iconName: 'chevron_right',
        canViewItem: ['admin','supervisor','operator'],
        enabled: ['admin','supervisor'],
        route: 'usuarios'
      },
      {
        displayName: 'Ajustes de seguridad',
        iconName: 'chevron_right',
        canViewItem: ['admin','supervisor','operator'],
        enabled: ['admin'],
        route: 'preferences'
      },
      {
        displayName: 'Capturar desde serial',
        iconName: 'chevron_right',
        canViewItem: ['admin','supervisor','operator'],
        enabled: ['admin','supervisor'],
        route: 'capture-sample-from-serial'
      }
    ]
  },
  {
    displayName: 'Administracion',
    iconName: 'blur_on',
    canViewItem: ['admin'],
    enabled: ['admin','supervisor','operator'],
    children: [
      {
        displayName: 'Tipo de Instrumento',
        iconName: 'chevron_right',
        canViewItem: ['admin'],
        enabled: ['admin','supervisor','operator'],
        route: 'equipos'
      },
      {
        displayName: 'Prop. Medibles',
        iconName: 'chevron_right',
        canViewItem: ['admin'],
        enabled: ['admin','supervisor','operator'],
        route: 'props-medibles'
      },
      {
        displayName: 'Unidades',
        iconName: 'chevron_right',
        canViewItem: ['admin'],
        enabled: ['admin','supervisor','operator'],
        route: 'unidades-medida'
      },
      {
        displayName: 'Grupos de unidades',
        iconName: 'blur_on',
        canViewItem: ['admin'],
        enabled: ['admin','supervisor','operator'],
        route: 'magnitudes'
      }
    ]
  }
];
