
  <p-table #tt [value]="modelos" [columns]="cols" dataKey="id" [loading]="loading" [rowHover]="true"
  [rows]="8" [paginator]="true" selectionMode="single" [(selection)]="selectedModel" [style]="{'font-size': '.8rem'}" sortField="marca.nombre">
  <ng-template pTemplate="caption">
    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
    <input type="text" pInputText class="p-inputtext-sm" size="50" placeholder="Filtro Global" (input)="tt.filterGlobal($event.target.value, 'contains')"
      style="width:auto">
    <button type="button" pButton class="p-button-outlined p-button-sm" (click)="resetFilters(tt)" label="Restablecer Filtros"></button>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns">{{col.header}}</th>
      <!-- <th style="width:40%">Parámetros</th> -->
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr [pSelectableRow]="rowData" >
      <!-- <td *ngFor="let col of columns">
        {{rowData[col.field]}}
      </td> -->
      <td>{{rowData.marca.nombre}}</td>
      <td>{{rowData.nombre}}</td>
      <td>{{rowData.descripcion}}</td>
      <!-- <td>
        <tr *ngFor="let p of rowData.parametrosMed">
          <td>
            <i>{{p.nombre}}: </i>
            <small>[Alcance]</small> {{p.alcanceInferior}}{{p.unidadMed.simbolo}} a {{p.alcanceSuperior}}{{p.unidadMed.simbolo}},&nbsp;&nbsp;</td>
          <td>
            <small>[Resolución]</small> {{p.resolucion}}{{p.unidadMed.simbolo}},&nbsp;&nbsp; </td>
          <td>
            <small>[Error Max]</small> &plusmn; &nbsp;{{p.errorMax}}{{p.unidadMed.simbolo}}</td>
        </tr>
      </td> -->
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
      <tr>
          <td [attr.colspan]="columns.length">
              No se encontraron registros
          </td>
      </tr>
  </ng-template>
</p-table>

<button mat-raised-button color="primary" (click)="selectModel()">Selecionar</button>
