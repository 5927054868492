import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from '@labqc-services/data.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { TestValidation } from './test-validation';

@Component({
  selector: 'app-tests-add-edit',
  templateUrl: './tests-add-edit.component.html',
  styleUrls: ['./tests-add-edit.component.scss']
})
export class TestsAddEditComponent implements OnInit, OnDestroy {
  newTestForm: UntypedFormGroup;

  modelInput = '';
  magnitudId: any;
  id: any;
  message: string;
  updateAction: boolean;

  // Combos
  // productos: any[any];
  productos$: Promise<any>;
  propsMedibles: any[any];
  propsMedibles$: Promise<any>;
  unidades: any[any];
  unidadesMed: any[any];

  // producto: any;
  propiedad: any;
  unidadMed: any;
  // numMuestras: number;

  // valNominal = 0;
  // t1Minus: number;
  // t1Plus: number;

  // t2Minus: number;
  // t2Plus: number;

  // plMinus: number;
  // plPlus: number;

  inputValue = 0;
  vista: any;

  vistas = [
    { viewValue: 'unidades', value: 'unidades' },
    { viewValue: 'porcenaje', value: 'porcentaje' }
  ];

  validationMessages: any;
  formErrors = {
    method: '',
    producto: '',
    propiedad: '',
    unidadMed: '',
    numMuestras: '',
    // modelo: '',
    valNominal: '',
    t1Minus: '',
    t1Plus: '',
    t2Minus: '',
    t2Plus: '',
    plMinus: '',
    plPlus: ''
  };

  constructor(
    private dataService: DataService,
    public dialogRef: MatDialogRef<TestsAddEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar,
    private fb: UntypedFormBuilder
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.getProducts();
    this.getPropsMedicion();
    this.getUnidadesMed();

    if (this.data) {
      this.fillUpdateForm();
      this.disableControls();
    }
  }

  ngOnDestroy() {

  }

  createForm() {
    this.newTestForm = this.fb.group(
      {
        method: ['', Validators.required],
        producto: ['', Validators.required],
        propiedad: ['', Validators.required],
        unidadMed: ['', Validators.required],
        numMuestras: ['', [Validators.required, Validators.max(100)]],
        // modelo: ['', Validators.required],
        valNominal: [0, Validators.required],
        t1Minus: ['', [Validators.required]],
        t1Plus: ['', Validators.required],
        t2Minus: ['', Validators.required],
        t2Plus: ['', Validators.required],
        plMinus: ['', Validators.required],
        plPlus: ['', Validators.required]
      },
      {
        validator: Validators.compose([
          TestValidation.plPlus,
          TestValidation.t2Plus,
          TestValidation.t1Plus,
          TestValidation.t1Minus,
          TestValidation.t2Minus,
          TestValidation.plMinus
        ])
      }
    );
    this.newTestForm.valueChanges.subscribe(data => this.onValueChanged(data));
    // this.onValueChanged(); // reset validation messa
  }

  onValueChanged(data?: any) {
    this.validationMessages = {
      method: { required: 'El nombre del metodo es obligatorio.' },
      producto: { required: 'Selecciona un producto.' },
      propiedad: { required: 'Selecciona una propiedad.' },
      unidadMed: { required: 'Selecciona una unidad de Medida.' },
      numMuestras: {
        required: 'Ingresa el número de muestras deseado.',
        max: 'El mayor número de muestras permitido es 100'
      },
      // modelo: { required: 'Selecciona un modelo de equipo a utilizar.' },

      plPlus: {
        required: 'Ingresa la Plausibilidad Sup.',
        plPlus: '+PL Debe ser mayor a +T2'
      },
      t2Plus: {
        required: 'Ingresa la Tolerancia 2 Sup.',
        t2Plus: '+T2 debe ser mayor a +T1'
      },
      t1Plus: {
        required: 'Ingresa la Tolerancia 1 Sup.',
        t1Plus: '+T1 debe ser mayor a Valor Nominal'
      },
      valNominal: {
        required: 'Debes  ingresar el valor nominal.',
        valNominal: 'El Valor Nominal debe ser diferente de 0'
      },
      t1Minus: {
        required: 'Ingresa la Tolerancia 1 Inf.',
        t1Minus: '-T1 debe ser menor Valor Nominal'
      },
      t2Minus: {
        required: 'Ingresa la Tolerancia 2 Inf.',
        t2Minus: '-T2 debe ser menor a -T1'
      },
      plMinus: {
        required: 'Ingresa la Plausibilidad Inf.',
        plMinus: '-PL debe ser menor a +T2'
      }
    };
    if (!this.newTestForm) {
      return;
    }
    const form = this.newTestForm;
    // tslint:disable-next-line:forin
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      this.newTestForm.controls[field].updateValueAndValidity({
        emitEvent: false
      });
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        // tslint:disable-next-line:forin
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  fillUpdateForm() {
    this.updateAction = true;
    this.id = this.data.id;
    this.newTestForm.patchValue({
      producto: this.data.producto,
      propiedad: this.data.propiedad,
      unidadMed: this.data.unidadMed,
      magnitudId: this.data.unidadMed.magnitudId,
      numMuestras: this.data.numMuestras,
      valNominal: this.data.valNominal,
      t1Minus: this.data.t1Minus,
      t1Plus: this.data.t1Plus,
      t2Minus: this.data.t2Minus,
      t2Plus: this.data.t2Plus,
      plMinus: this.data.plMinus,
      plPlus: this.data.plPlus,
      // modelo: this.data.modelo
    });
    this.unidadMed = this.data.unidadMed;
    // this.modelInput = `${this.data.modelo.marca.nombre} - ${
    //   this.data.modelo.nombre
    // }`;
  }

  disableControls() {
    this.newTestForm.controls['method'].disable();
    this.newTestForm.controls['producto'].disable();
    this.newTestForm.controls['propiedad'].disable();
    this.newTestForm.controls['unidadMed'].disable();

    this.newTestForm.controls['plPlus'].disable();
    this.newTestForm.controls['t2Plus'].disable();
    this.newTestForm.controls['t1Plus'].disable();
    this.newTestForm.controls['valNominal'].disable();
    this.newTestForm.controls['t1Minus'].disable();
    this.newTestForm.controls['t2Minus'].disable();
    this.newTestForm.controls['plMinus'].disable();
  }

  // searchModel() {
  //   this.propiedad = this.newTestForm.controls['propiedad'];
  //   if (this.propiedad.valid) {
  //     const dialogRef = this.dialog.open(ModelSearchComponent, {
  //       data: this.propiedad.value
  //     });
  //     dialogRef
  //       .afterClosed()
  //       .pipe(take(1))
  //       .subscribe(result => {
  //         if (result) {
  //           this.newTestForm.patchValue({
  //             modelo: result
  //           });
  //           this.modelInput = `${result.marca.nombre} - ${result.nombre}`;
  //         }
  //       });
  //   } else {
  //     this.messageService.add({
  //       severity: 'warn',
  //       summary: 'Alerta',
  //       detail: 'Seleccione una propiedad medible'
  //     });
  //   }
  // }

  // clearModelInput() {
  //   this.newTestForm.clearValidators();
  //   this.newTestForm.patchValue({
  //     modelo: null
  //   });
  //   this.modelInput = '';
  // }

  async getProducts() {
    this.productos$ = this.dataService.getAll('productos');
  }

  async getPropsMedicion() {
    this.propsMedibles$ = this.dataService.getAll('propsMedibles');
  }

  async getUnidadesMed() {
    this.unidadesMed  = await this.dataService.getAll('unidadesMed');
    if (this.data) {
      this.unidades = this.unidadesMed.filter(
        unidad => unidad.magnitud.id === this.data.propiedad.magnitud.id
      );
    }

  }

  submit() {
    if (!this.updateAction) {
      this.dataService
        .create(this.newTestForm.getRawValue(), 'catPruebas')
        .then(res => {
          this.message = 'Producto Guardado';
          this.openSnackBar();
          console.log(res);
          this.dialogRef.close(res);
        });
    } else {
      this.actualizaOrden();
    }
  }

  async actualizaOrden() {
    if (this.updateAction) {
      this.message = 'Actualizando documento...';
      this.openSnackBar();
      await this.dataService.update(this.id,this.newTestForm.getRawValue(),'catPruebas');
      this.message = 'Documento Actualizado!!!';
      this.openSnackBar();
      this.dialogRef.close();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openSnackBar() {
    this.snackBar.open(this.message, null, {
      duration: 1500
    });
  }

  onSelectProducto() {
    this.newTestForm.patchValue({
      propiedad: '',
      unidadMed: '',
      // modelo: ''
    });
    this.unidadMed = '';
  }

  onSelectPropiedad($event) {
    this.unidades = this.unidadesMed.filter(
      unidad => unidad.magnitud.id === $event.value.magnitud.id
    );
    this.newTestForm.patchValue({
      unidadMed: '',
      // modelo: ''
    });
    this.unidadMed = '';
    this.modelInput = '';
  }

  onSelectUnidadMed($event) {
    console.log($event);
    this.unidadMed = $event.value;
  }

  compareProduct(o1: any, o2: any): boolean {
    return o1.id === o2.id;
  }

  compareProp(o1: any, o2: any): boolean {
    return o1.id === o2.id;
  }

  compareUnidad(o1: any, o2: any): boolean {
    return o1.id === o2.id;
  }

  convertToPercentage() {
    const plPlus = this.newTestForm.controls['plPlus'].value;
    const t2Plus = this.newTestForm.controls['t2Plus'].value;
    const t1Plus = this.newTestForm.controls['t1Plus'].value;
    const valNominal = this.newTestForm.controls['valNominal'].value;
    const t1Minus = this.newTestForm.controls['t1Minus'].value;
    const t2Minus = this.newTestForm.controls['t2Minus'].value;
    const plMinus = this.newTestForm.controls['plMinus'].value;

    if (valNominal !== 0) {
      this.newTestForm.patchValue({
        plPlus: valNominal + valNominal * plPlus / 100,
        t2Plus: valNominal + valNominal * t2Plus / 100,
        t1Plus: valNominal + valNominal * t1Plus / 100,
        t1Minus: valNominal + valNominal * t1Minus / 100,
        t2Minus: valNominal + valNominal * t2Minus / 100,
        plMinus: valNominal + valNominal * plMinus / 100
      });
    } else {
      alert('Debe ingresar valores superiores a 0');
    }
  }

  compareVista() {}

  onSelectVista($event) {
    console.log($event.value);
  }

  modelChanged(event) {
    console.log(event);

    // console.log('antes:', this.inputValue);
    this.inputValue = this.newTestForm.controls['valNominal'].value / 100;
    // console.log('despues:', this.inputValue);
  }
}
