<div class="about-container">
  <div class="gradient">
    <div class="flex flex-col">
      <!-- <canvas #trianglify></canvas> -->

      <!-- <div class="login-header"> -->
      <h2>LEMOTEK
        <br />
        <br />Quality Control Software
      </h2>
      <!-- </div> -->
    </div>

    <div class="flex flex-col items-center">
      <img src="/assets/img/SmartLink_logo(wt).png" width="600px" alt="SmartLink Logo">
      <h5 style="color: white">v{{currentApplicationVersion}} Build: {{buildRevision}}</h5>
    </div>
  </div>
</div>
