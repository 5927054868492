import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthService } from './auth.service';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AdminGuard } from './admin.guard';
import { CanReadGuard } from './can-read.guard';

@NgModule({
  imports: [
    // CommonModule
    AngularFirestoreModule,
    AngularFireAuthModule
  ],
  declarations: [],
  providers: [AuthService, AdminGuard, CanReadGuard]
})
export class CoreModule {
  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
 }
