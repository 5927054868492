<app-navbar class="w-full inline-block sm:hidden"></app-navbar>
<mat-sidenav-container fullscreen>
  <app-navbar class="w-full hidden sm:inline-block" ></app-navbar>

  <!-- <p-contextMenu [global]="true" [model]="items"></p-contextMenu> -->
  <mat-sidenav #appDrawer mode="over" position="start" opened="false">
    <mat-nav-list>
      <app-menu-list-item *ngFor="let item of navItems" [item]="item"></app-menu-list-item>
      <a mat-list-item *ngIf="!(afAuth.authState | async)" (click)="login()">
        <mat-icon class="routeIcon">person</mat-icon>Entrar
      </a>
      <a mat-list-item *ngIf="(afAuth.authState | async)" (click)="fireLogout()">
        <mat-icon class="routeIcon">exit_to_app</mat-icon>Cerrar Sesión
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <div class="router-bg">
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>

<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>
