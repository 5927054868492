import { Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { DataService } from '@labqc-services/data.service';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { gsap } from 'gsap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  subsAuth: Subscription;
  currentApplicationVersion = environment.appVersion;
  buildRevision: number;
  @ViewChild('logo') logo: ElementRef;
  private get logoEl(): HTMLElement {
    return this.logo.nativeElement;
  }
  constructor(
    private dataService: DataService) {
  }

  ngOnInit() {
    this.dataService.getJSON().subscribe(res=>this.buildRevision = res.buildRevision);
  }

  ngAfterViewInit(){
    this.animate();
  }

  public animate() {
    gsap.from(this.logoEl,{duration: 1, y:120,  scale: 2});
  }

  ngOnDestroy() {
  }


}
