<form #productForm="ngForm" (ngSubmit)="submit()">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Producto</mat-card-title>
      <mat-card-subtitle>Detalles</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>

      <div class="grid-container">
        <div class="column-one">
          <section>
            <mat-form-field dividerColor="primary">
              <input matInput name="valorRef" [(ngModel)]="productName" type="text" placeholder="Nombre" required>
            </mat-form-field>
          </section>

          <section>
            <mat-form-field dividerColor="primary">
              <input matInput name="etiqueta" [(ngModel)]="code" type="text" placeholder="Código de producto" required>
            </mat-form-field>
          </section>

          <section>
            <mat-form-field dividerColor="primary">
              <input matInput name="tolerancia" [(ngModel)]="description" type="text" placeholder="Descripción" required>
            </mat-form-field>
          </section>

          <section>
            <mat-form-field dividerColor="primary">
              <mat-select  name="productType" [(ngModel)]="productType"  placeholder="Tipo de producto" required [compareWith]="compareProp" (selectionChange)="selectedProduct($event)" >
                <mat-option *ngFor="let type of productTypeList | async" [value]="type">
                  {{type.nombre}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </section>

          <section>
              <mat-checkbox name="active" [(ngModel)]="active">Activo</mat-checkbox>
          </section>
        </div>

        <!-- <div class="column-two">
          <section class="attributes">
            <h4>Attributes</h4>
            <mat-checkbox *ngFor="let chk of checks, let index=index" [disabled]="chk.disabled" [checked]="chk.checked" (change)="valueChange(index, $event)"
              class="checkboxes">{{chk.nombre}}
              <br>
            </mat-checkbox>
          </section>
        </div> -->
      </div>

    </mat-card-content>
    <mat-card-actions align="start">
      <button mat-raised-button color="primary" type="submit" [disabled]="!productForm.valid" *ngIf="!updateAction">Guardar</button>
      <button mat-raised-button type="submit" color="accent" [disabled]="!productForm.valid" *ngIf="updateAction">Actualizar y Cerrar</button>
      <button mat-raised-button type="button" color="basic" (click)="closeDialog()">Cerrar</button>
    </mat-card-actions>
  </mat-card>
</form>
