<form #unidadesMedForm="ngForm" (ngSubmit)="guardarUnidad()">
  <mat-form-field>
    <input matInput type="text" name="nombre" [(ngModel)]="unidad" placeholder="Nombre" required>
  </mat-form-field>
  <mat-form-field>
    <input matInput type="text" name="simbolo" [(ngModel)]="simbolo" placeholder="Símbolo" required>
  </mat-form-field>

  <mat-form-field>
    <mat-select name="magnitud" [(ngModel)]="magnitud" placeholder="Magnitud" required [compareWith]="compareMagnitud">
      <mat-option *ngFor="let magnitud of magnitudes" [value]="magnitud">
        {{magnitud.nombre}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-raised-button type="submit" [disabled]="!unidadesMedForm.valid">Agregar</button>
</form>
